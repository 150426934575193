import { getCookie } from "./cookie.js";
import VueCookies from "vue-cookies";

export function checkLogin() {
  if (getCookie("token")) {
    return true;
  } else {
    return false;
  }
}
