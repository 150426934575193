<template>
  <div class="article-manage">
    <admin-thesis-manage
      v-if="$store.state.userInfo.roleId === 4"
    ></admin-thesis-manage>
    <user-thesis-mamage v-else></user-thesis-mamage>
  </div>
</template>

<script>
import UserThesisManage from "./ordinaryUser/UserThesisManage.vue";
import AdminThesisManage from "./systemAdmin/AdminThesisManage.vue";

export default {
  components: {
    "user-thesis-mamage": UserThesisManage,
    "admin-thesis-manage": AdminThesisManage,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.article-manage {
  height: 100%;
  width: 100%;
  padding: 10px;
}
</style>
