<template>
  <div class="user-metadata-manage">
    <el-row class="operation-row">
      <el-button type="primary" size="mini" @click="addmetadataEvent">
        添加研究资料</el-button>
    </el-row>
    <el-divider></el-divider>
    <el-row class="operation-table">
      <!-- 添加具有编辑功能的表格 -->
      <vxe-table
              align="center"
              border
              stripe
              :row-config="{ isCurrent: true, isHover: true }"
              :data="metadataList"
              height="100%"
      >
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column field="dataTitle" title="资料名称"></vxe-column>
        <vxe-column field="dataOwner" title="完成人"></vxe-column>
        <vxe-column field="dataContent" title="资料简介"></vxe-column>
        <vxe-column field="dataURL" title="资料链接">
          <template #default="{ row }">
            <a :href="row.dataUrl" >{{ row.dataUrl }}</a>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="200">
          <template #default="{ row }">
            <vxe-button
                    type="text"
                    icon="fa fa-edit"
                    @click="editEvent(row)"
            ></vxe-button>
            <vxe-button
                    type="text"
                    icon="fa fa-trash-o"
                    @click="removeEvent(row)"
            ></vxe-button>
            <vxe-button
                    type="text"
                    icon="fa fa-chevron-down"
                    @click="downloadEvent(row.dataUrl)"
            ></vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </el-row>
    <vxe-modal
            v-model="metadataModelVisible"
            width="600"
            height="400"
            :title="modalTitle"
    >
      <vxe-form
              :title-width="100"
              title-align="right"
              :data="metadataForm"
              :rules="metadataFormRules"
              @submit="submitEvent"
              ref="metadataForm"
      >
        <!-- 活动编号 -->
        <vxe-form-item
                title="资料编号"
                field="activeId"
                :item-render="{}"
                :span="24"
        >
          <template #default="{ data }">
            <vxe-input
                    v-model="data.dataId"
                    placeholder="请输入资料编号"
                    clearable
            ></vxe-input>
          </template>
        </vxe-form-item>

        <!-- 活动名称 -->
        <vxe-form-item
                title="资料名称"
                field="dataTitle"
                :item-render="{}"
                :span="24"
        >
          <template #default="{ data }">
            <vxe-input
                    v-model="data.dataTitle"
                    placeholder="请输入资料名称"
                    clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 完成人 -->
        <vxe-form-item
                title="完成人"
                field="dataOwner"
                :item-render="{}"
                :span="12"
        >
          <template #default="{ data }">
            <vxe-input
                    v-model="data.dataOwner"
                    placeholder="请输入完成人"
                    clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 资料链接 -->
        <vxe-form-item
                title="资料链接"
                field="dataURL"
                :item-render="{}"
                :span="24"
        >
          <template #default="{ data }">
            <vxe-input
                    v-model="data.dataURL"
                    placeholder="请输入资料链接"
                    clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <!--资料简介-->
        <vxe-form-item
                title="资料简介"
                field="dataContent"
                :item-render="{}"
                :span="24"
        >
          <template #default="{ data }">
            <vxe-input
                    v-model="data.dataContent"
                    placeholder="请输入资料简介"
                    clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <!--&lt;!&ndash; 活动图片 &ndash;&gt;-->
        <!--<vxe-form-item title="活动图片" field="activeImg">-->
          <!--<template #default="{}">-->
            <!--<input type="file" @change="fileChange($event)" />-->
          <!--</template>-->
        <!--</vxe-form-item>-->
        <!-- 提交 -->
        <vxe-form-item align="center" span="24" :item-render="{}">
          <template>
            <vxe-button type="submit" status="primary">提交</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </div>
  <!-- 编辑弹窗 -->
</template>

<script>
import {
    getMetadataList,
    getMetadataListByUserId,
    delMetadata,
    addMetadata,
    modifyMetadata,
} from "../../api/metadata";
export default {
    data() {
        return {
            metadataList: [],
            metadataModelVisible: false,
            modalTitle: "",
            metadataForm: {
                dataId: "",
                dataTitle: "",
                dataOwner: "",
                dataURL: "",
                initiator: this.$store.state.userInfo.userName,
                initiatorId: this.$store.state.userInfo.userId,
                dataContent: "",
            },
            metadataFormRules: {},
        };
    },
    beforeMount() {
        this.getMetadataList();
    },
    methods: {
        // 获取研究资料（发起人）
        getMetadataList() {
            let this_ = this;
            getMetadataList()
                .then((response) => {
                    this_.metadataList = response.data;
                })
                .catch((err) => {
                    this_.$message({
                        message: err,
                        type: "error",
                    });
                });
        },
        // 点击添加活动信息的按钮
        addmetadataEvent() {
            this.metadataModelVisible = true;
            this.modalTitle = "新增活动信息";
            this.metadataForm = {
                dataId: "",
                dataTitle: "",
                dataOwner: "",
                dataURL: "",
                initiator: this.$store.state.userInfo.userName,
                initiatorId: this.$store.state.userInfo.userId,
                dataContent: "",
            };
        },
        // 编辑活动信息
        editEvent(row) {
            this.metadataModelVisible = true;
            this.modalTitle = "修改活动信息";
            this.metadataForm = row;
        },
        // 删除活动信息
        removeEvent(row) {
            let this_ = this;
            this_.$XModal.confirm("您确定要删除研究资料吗？").then((type) => {
                if (type == "cancel") {
                } else {
                    delMetadata({dataId: row.dataId})
                        .then((response) => {
                            this_.$message({
                                message: "删除成功",
                                type: "success",
                            });
                        })
                        .catch((error) => {
                            this_.message({
                                message: error,
                                type: "error",
                            });
                        });
                }
            });
        },
        // 提交按钮事件
        submitEvent() {
            let this_ = this;
            this_.$refs.metadataForm.validate((result) => {
                if (result === undefined) {
                    if (this_.modalTitle === "新增研究资料") {
                        addMetadata(this_.metadataForm)
                            .then((response) => {
                                this_.$message({
                                    message: "新增研究资料成功",
                                    type: "success",
                                });
                            })
                            .catch((error) => {
                                this_.$message({
                                    message: error,
                                    type: "error",
                                });
                            });
                    } else {
                        modifyMetadata(this_.metadataForm)
                            .then((response) => {
                                this_.$message({
                                    message: "修改研究资料成功",
                                    type: "success",
                                });
                            })
                            .catch((error) => {
                                this_.$message({
                                    message: error,
                                    type: "error",
                                });
                            });
                    }
                }
            });
        },
        // 文件选择
        downloadEvent(dataUrl) {
                    let this_ = this;
                    const apiurl = dataUrl; //接口地址
                    console.log(apiurl);
                    this_.exportLoading = true;
                    this_.axios.post(apiurl, params, {
                        'responseType': 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
                    }).then( (response) =>{
                        console.log('response', response, response.data.size);
                        this_.exportLoading = false;
                        if(response.data){
                            if(response.data.size < 1000){
                                // 根据文件流的大小判断异常情况
                                if(response.data.size == 63){
                                    this.$message.warning('查无结果');
                                    return
                                }
                                if(response.data.size == 84){
                                    this.$message.warning('导出数据超出最大限制值');
                                    return
                                }
                            }else{
                                const blob = new Blob([response.data],{type: 'application/vnd.ms-excel'});
                                const linkNode = document.createElement('a');
                                linkNode.style.display = 'none';
                                linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                                document.body.appendChild(linkNode);
                                linkNode.click();  //模拟在按钮上的一次鼠标单击
                                URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                                document.body.removeChild(linkNode);
                            }
                        }
                    }).catch( (error) =>{
                        console.log(error);
                        this_.exportLoading = false
                    });
                },
            },
}
</script>

<style lang="scss" scoped>
.user-metadata-manage {
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  .operation-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .operation-table {
    height: calc(100vh - 210px);
  }
}
</style>
