<template>
  <div class="patent-manage">
    <admin-patent-manage
      v-if="$store.state.userInfo.roleId === 4"
    ></admin-patent-manage>
    <user-patent-manage v-else></user-patent-manage>
  </div>
</template>

<script>
import UserPatentManage from "./ordinaryUser/UserPatentManage.vue";
import AdminPatentManage from "./systemAdmin/AdminPatentManage.vue";

export default {
  components: {
    "user-patent-manage": UserPatentManage,
    "admin-patent-manage": AdminPatentManage,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.patent-manage {
  height: 100%;
  padding: 10px;
}
</style>
