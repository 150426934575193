<template>
  <div class="intro">
    <div class="top-img">
      <img src="http://www.hrbnu.edu.cn/images/c.jpg" alt="" />
    </div>
    <div class="intro-text">
      <p>
        <b>一、实验室简介</b>
      </p>
      <p>
        人工智能实验室，现有指导教师4人，兼职指导教师3人，主要从事深度学习、置信规则库、证据推理理论研究，大型结构设备故障诊断、故障预测、安全评估等工程应用研究。实验室目前在研国家级项目1项，省级项目2项，横向项目多项，发表学术论文近百篇。
      </p>
      <p>
        实验室研究生勤奋努力，积极进去取得众多成果：<br></p>

      <p> 1、以一作或二作发表<b>SCI论文40余篇，EI论文12篇，核心论文20余篇</b>；</p>

      <p>  2、以一作或二作申请<b>实用新型专利6项，软件著作权6项</b>；</p>

      <p>  3、获得2020年挑战杯国赛三等奖、省赛金奖，2020年互联网+省赛银奖，2020年黑龙江人工智能大赛金奖，2021年互联网+省赛银奖，2022年挑战杯省赛金奖，2022年互联网+省赛铜奖；<br></p>

      <p>  4、并参与多项横向课题和纵向课题的研究和开发工作。</p>

      <p> 目前，实验室处于高速发展阶段，预计年均发表核心以上<b>科技论文40篇以上，其中SCI论文超过30篇以上，软件专利10项以上，完成横向课题3项以上</b>。</p>

      <p>
        <b>二、实验室主要工作</b>
      </p>
      <p> 1、科技论文写作：利用C、Python、MatLab独立或合作进行科研实验，根据科学研究成果撰写科技论文；</p>

      <p> 2、专利、软著申请：根据科学研究和项目开发完成相关发明专利、实用新型、软件著作；</p>

      <p> 3、核心算法研发：根据工程任务，编写matLab程序并完成C语言的代码转换；</p>

      <p> 4、工程项目开发：根据工程项目，利用VUE+Spring Boot前后端开发技术完成项目代码开发；</p>

      <p> 5、创新创业竞赛：以团队形式参加互联网+、挑战杯、创新杯的项目竞赛；</p>

      <p>
      <b>三、实验室毕业条件（满足一下条件其一）：</b>
      </p>
      <p>
        1、一作或者二作发表两篇SCI论文；</p>

      <p> 2、一作或者二作发表SCI一篇并且发表2篇核心或者EI论文；</p>

      <p>3、发表核心或者EI论文2篇（或者SCI一篇）并且参加互联网+或挑战杯获得省赛金奖；</p>

      <p> 4、发表核心或者EI论文2篇（或者SCI一篇）并且参与横向课题研究；</p>

      <p> 5、发表核心或者EI论文2篇（或者SCI一篇）并且参与申请发明专利。</p>

      <p>
        <b>四、研究生加入实验室条件（二选一）</b>
      </p>
      <p>
        1、学术型：刻苦努力、有积极向上的态度，未来想继续深造读博、且通过大学英语6级（或者通过4级但是有EI/SCI论文写作经验）。</p>
      <p>
        2、应用型：刻苦努力、有积极向上的态度，掌握VUE+Spring Boot前后端开发技术   或者 掌握嵌入式软件与硬件开发技术   或者 能够使用C语言、Python完成核心算法编写。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 1300px;
  background-color: #eee;
  .top-img {
    padding: 10px;
  }
  .intro-text {
    padding: 10px;
    width: 1000px;
    text-align: left;
    p {
      text-indent: 2em;
    }
  }
}
</style>
