import request from "../https/request";

// 获取所有的项目信息
export function getProjectList() {
  return request({
    url: "/project",
    method: "get",
  });
}

// 根据用户的id获取项目列表
export function getProjectListById(userId) {
  return request({
    url: "/project/" + userId,
    method: "get",
  });
}

export function addProject(data) {
  return request({
    url: "/project",
    method: "post",
    data,
  });
}

export function modifyProject(data) {
  return request({
    url: "/project/modify",
    method: "post",
    data,
  });
}

export function delProject(data) {
  return request({
    url: "/project",
    method: "delete",
    params: data
  });
}
