<template>
  <div class="layout">
    <el-container class="layout_container">
      <el-header>
        <el-row class="title-row">
          <div class="title">
            哈尔滨师范大学计算机科学与信息工程学院人工智能实验室
          </div>
        </el-row>
        <el-row class="menu-row">
          <el-menu
            :default-active="activeIndex"
            :router="true"
            class="el-menu-demo"
            mode="horizontal"
            background-color="#790000"
            text-color="#fff"
            active-text-color="#ffd04b"
            style="width: 500px"
          >
            <el-menu-item index="/Layout/Index"
              ><svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shouye"></use></svg
              >首页</el-menu-item
            >
            <el-menu-item index="/Layout/Intro">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-yuanxiaojianjie"></use>
              </svg>
              简介</el-menu-item
            >
            <el-menu-item index="/Layout/Teacher">
              <svg class="icon" aria-hidden="true">
                <use
                  xlink:href="#icon-a-teacherlaoshijiaoshinanrenrenwutouxiang"
                ></use></svg
              >教师</el-menu-item
            >
            <el-menu-item index="/Layout/Student">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shiyongxuesheng"></use></svg
              >学生</el-menu-item
            >
          </el-menu>
          <div class="userinfo">
            当前用户: {{ $store.state.userInfo.userName }}
          </div>
          <div class="button-group">
            <el-button size="small" @click="logout()"
              ><svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-dengchu"></use></svg
              >登出</el-button
            >
            <el-button size="small" @click="login()"
              ><svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-login"></use></svg
              >登录</el-button
            >
            <el-button size="small" @click="administration()">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-houtaiguanli"></use></svg
              >后台管理</el-button
            >
          </div></el-row
        >
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <div class="ICP">
          <a target="_blank" href="https://beian.miit.gov.cn/">黑ICP备2022003573号-1</a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          @2022-2025   哈尔滨师范大学计算机科学与信息工程学院人工智能实验室 &nbsp;&nbsp;  All Rights Reserved
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { checkLogin } from "../utils/public";

export default {
  data() {
    return {
      activeIndex: "/Layout/Index",
    };
  },
  created: function () {},
  methods: {},
  beforeMount() {
    let path = this.$route.path;
    this.activeIndex = path;
  },
  methods: {
    // 登出按钮处理方法

    logout() {
      let this_ = this;
      if (checkLogin()) {
        this_
          .$confirm("此操作将退出系统的登录状态, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            //点击确定的操作(调用接口)
            this_.$cookies.remove("token");
            this_.$router.push("/");
          })
          .catch(() => {
            //几点取消的提示
          });
      } else {
        this.$message({
          type: "error",
          message: "您尚未登录系统，请点击登录按钮登录系统。",
        });
      }
    },
    // 登录按钮处理方法
    login() {
      let this_ = this;
      if (checkLogin()) {
        this_.$message({
          type: "success",
          message: "您已经登录系统，如果需要切换账号，请先退出登录。",
        });
      } else {
        this_.$cookies.remove("token");
        this_.$router.push("/");
      }
    },
    // 后台管理按钮处理方法
    administration() {
      if (checkLogin()) {
        this.$router.push(
          "/admin/manage-welcome/" + this.$store.state.userInfo.userid
        );
      } else {
        this.$message({
          type: "warning",
          message: "您尚未登录系统请登陆后进行后台管理。",
        });
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.layout {
  height: 100%;
  .layout_container {
    height: 100%;
    .el-header,
    .el-main,
    .el-footer {
      padding: 0;
      margin: 0;
      .ICP {
        margin-top: 20px;
        color: white;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
    .el-header,
    .el-footer {
      background-color: #790000;
    }
    .el-header {
      height: auto !important;
      .title-row {
        margin-top: 10px;
        .title {
          width: 100%;
          height: 100%;
          padding: 10px;
          background-color: #fff;
          font-size: 26px;
          font-family: "黑体";
          font-weight: bold;
          color: #790000;
        }
      }
      .menu-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .el-menu-item {
          .el-menu--horizontal > .el-menu-item {
            // width: 100px;
            font-size: 16px;
          }
          .icon {
            font-size: 20px;
            margin-right: 5px;
          }
        }
        .button-group {
          padding-right: 20px;
          width: 300px;
          .el-button {
            color: #790000;
            font-weight: bold;
            .icon {
              font-size: 14px;
              margin-right: 5px;
            }
          }
        }
        .userinfo {
          display: inline-block;
          width: 200px;
          color: #ffd04b;
        }
      }
    }
    .el-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: calc(100vh - 100px);
      background-color: #dedede;
    }
  }
}
</style>
