<template>
  <div class="user-patent-manage">
    <el-row class="operation-row">
      <el-button type="primary" size="mini" @click="addPatentEvent"
        >新增专利信息</el-button
      >
    </el-row>
    <el-divider></el-divider>
    <el-row class="operation-table">
      <vxe-table
        align="center"
        border
        stripe
        :row-config="{ isCurrent: true, isHover: true }"
        :data="patentList"
        height="100%"
      >
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column field="patentNo" title="专利号"></vxe-column>
        <vxe-column field="patentName" title="专利名称"></vxe-column>
        <vxe-column field="inventer" title="发明人"></vxe-column>
        <vxe-column
          field="patentDate"
          title="专利发表日期"
          width="100"
        ></vxe-column>
        <vxe-column field="patentImg" title="专利图片" width="250">
          <template #default="{ row }">
            <img
              :src="row.patentImg"
              alt=""
              style="width: 200px; height: 100px"
            />
          </template>
        </vxe-column>
        <vxe-column title="操作" width="100">
          <template #default="{ row }">
            <vxe-button
              type="text"
              icon="fa fa-edit"
              @click="editEvent(row)"
            ></vxe-button>
            <vxe-button
              type="text"
              icon="fa fa-trash-o"
              @click="removeEvent(row)"
            ></vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </el-row>
    <vxe-modal
      v-model="patentModelVisible"
      width="600"
      height="400"
      :title="modalTitle"
    >
      <vxe-form
        :title-width="100"
        title-align="right"
        :data="patentForm"
        :rules="patentFormRules"
        @submit="submitEvent"
        ref="patentForm"
      >
        <!-- 专利号 -->
        <vxe-form-item
          title="专利号"
          field="patentNo"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input
              v-model="data.patentNo"
              placeholder="请输入专利号"
              clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 专利名称 -->
        <vxe-form-item
          title="专利名称"
          field="patentName"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input
              v-model="data.patentName"
              placeholder="请输入专利名称"
              clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 专利发表 -->
        <vxe-form-item
          title="专利发表日期"
          field="patentDate"
          :item-render="{}"
          :span="12"
        >
          <template #default="{ data }">
            <vxe-input type="date" v-model="data.patentDate"></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 专利图片 -->
        <vxe-form-item title="专利图片" field="patentImg">
          <template #default="{}">
            <input type="file" @change="fileChange($event)" />
          </template>
        </vxe-form-item>
        <!-- 提交 -->
        <vxe-form-item align="center" span="24" :item-render="{}">
          <template>
            <vxe-button type="submit" status="primary">提交</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </div>
</template>

<script>
import {
  getPatentListByUserId,
  delPatent,
  addPatent,
  modifyPatent,
} from "../../../api/patent";

export default {
  data() {
    return {
      patentList: [],
      patentModelVisible: false,
      modalTitle: "",
      //
      patentForm: {
        patentNo: "",
        patentName: "",
        inventerId: this.$store.state.userInfo.userId,
        inventer: this.$store.state.userInfo.userName,
        patentDate: "",
        patentImg: "",
      },
      patentFormRules: {
        patentNo: [{ required: true, message: "请填写专利号" }],
        patentName: [{ required: true, message: "请填写专利名称" }],
        patentDate: [{ required: true, message: "请填写专利发表日期" }],
      },
    };
  },
  mounted() {
    this.getPatentListByUserId();
  },
  methods: {
    // 获取专利列表数据
    getPatentListByUserId() {
      let this_ = this;
      getPatentListByUserId(this.$store.state.userInfo.userId)
        .then((response) => {
          this_.patentList = response.data;
        })
        .catch((error) => {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    // 点击添加专利按钮
    addPatentEvent() {
      this.patentModelVisible = true;
      this.modalTitle = "新增专利信息";
      this.patentForm = {
        patentNo: "",
        patentName: "",
        inventerId: this.$store.state.userInfo.userId,
        inventer: this.$store.state.userInfo.userName,
        patentDate: "",
        patentImg: "",
      };
    },
    // 编辑专利按钮
    editEvent(row) {
      this.patentModelVisible = true;
      this.modalTitle = "修改专利信息";
      this.patentForm = row;
    },
    // 删除专利按钮
    removeEvent(row) {
      let this_ = this;
      this_.$XModal.confirm("您确定要删除专利信息吗？").then((type) => {
        if (type == "cancel") {
        } else {
          delPatent({ patentNo: row.patentNo })
            .then((response) => {
              this_.$message({
                message: "删除成功",
                type: "success",
              });
            })
            .catch((error) => {
              this_.message({
                message: error,
                type: "error",
              });
            });
        }
      });
    },
    // 图片文件改变
    fileChange(event) {
      let this_ = this;
      const files = event.target.files[0];
      this.patentForm.patentImg = files[0];
      var reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function (e) {
        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
        var imgcode = e.target.result;
        this_.patentForm.patentImg = imgcode;
      };
    },
    // 提交按钮被点击
    submitEvent() {
      let this_ = this;
      this_.$refs.patentForm.validate((result) => {
        if (result === undefined) {
          if (this_.modalTitle === "新增专利信息") {
            addPatent(this_.patentForm)
              .then((response) => {
                this_.$message({
                  message: "新增专利成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          } else {
            modifyPatent(this_.patentForm)
              .then((response) => {
                this_.$message({
                  message: "项目专利成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-patent-manage {
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  .operation-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .operation-table {
    height: calc(100vh - 210px);
  }
}
</style>
