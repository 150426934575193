<template>
  <div class="user-match-manage">
    <el-row class="operation-row">
      <el-button type="primary" size="mini" @click="addMatchEvent"
        >新增比赛信息</el-button
      >
    </el-row>
    <el-divider></el-divider>
    <el-row class="operation-table">
      <vxe-table
        align="center"
        border
        stripe
        :row-config="{ isCurrent: true, isHover: true }"
        :data="matchList"
        height="100%"
      >
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column field="matchTitle" title="比赛名称"></vxe-column>
        <vxe-column field="hostName" title="主持人"></vxe-column>
        <vxe-column field="partName" title="参与人"></vxe-column>
        <vxe-column field="teacherName" title="指导教师"></vxe-column>
        <vxe-column field="level" title="比赛级别" width="100">
          <template #default="{ row }">
            <el-tag>{{ row.level }}</el-tag>
          </template>
        </vxe-column>
        <vxe-column field="matchDate" title="比赛时间" width="100"></vxe-column>
        <vxe-column field="matchImg" title="比赛图片" width="250">
          <template #default="{ row }">
            <img
              :src="row.matchImg"
              alt=""
              style="width: 200px; height: 100px"
            />
          </template>
        </vxe-column>
        <!-- 操作列 -->
        <vxe-column title="操作" width="100">
          <template #default="{ row }">
            <vxe-button
              type="text"
              icon="fa fa-edit"
              @click="editEvent(row)"
            ></vxe-button>
            <vxe-button
              type="text"
              icon="fa fa-trash-o"
              @click="removeEvent(row)"
            ></vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </el-row>
    <vxe-modal
      v-model="matchModelVisible"
      width="600"
      height="400"
      :title="modalTitle"
    >
      <vxe-form
        :title-width="100"
        title-align="right"
        :data="matchForm"
        :rules="matchFormRules"
        @submit="submitEvent"
        ref="matchForm"
      >
        <!-- 比赛名称 -->
        <vxe-form-item
          title="比赛名称"
          field="matchTitle"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input
              v-model="data.matchTitle"
              placeholder="请输入比赛名称"
              clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 参与人 -->
        <vxe-form-item
          title="参与人"
          field="partName"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input v-model="data.partName"></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 指导教师 -->
        <vxe-form-item
          title="指导教师"
          field="teacherName"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input v-model="data.teacherName"></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 比赛日期 -->
        <vxe-form-item
          title="比赛日期"
          field="matchDate"
          :item-render="{}"
          :span="12"
        >
          <template #default="{ data }">
            <vxe-input type="date" v-model="data.matchDate"></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 比赛等级 -->
        <vxe-form-item
          title="比赛等级"
          field="level"
          :item-render="{}"
          :span="12"
        >
          <template #default="{ data }">
            <vxe-select v-model="data.level">
              <vxe-option value="校级" label="校级"></vxe-option>
              <vxe-option value="市级" label="市级"></vxe-option>
              <vxe-option value="省级" label="省级"></vxe-option>
              <vxe-option value="国家级" label="国家级"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <!-- 比赛图片 -->
        <vxe-form-item title="比赛图片" field="matchImg">
          <template #default="{}">
            <input type="file" @change="fileChange($event)" />
          </template>
        </vxe-form-item>
        <!-- 提交 -->
        <vxe-form-item align="center" span="24" :item-render="{}">
          <template>
            <vxe-button type="submit" status="primary">提交</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </div>
</template>

<script>
import {
  getMatchListByUserId,
  delMatch,
  addMatchInfo,
  modifyMatchInfo,
} from "../../../api/macths";
export default {
  data() {
    return {
      matchList: [],
      matchModelVisible: false,
      modalTitle: "",
      // 表单数据
      matchForm: {
        matchId: "",
        matchTitle: "",
        hostName: this.$store.state.userInfo.userName,
        hostId: this.$store.state.userInfo.userId,
        partName: "",
        teacherName: "",
        level: "",
        matchDate: "",
        matchImg: "",
      },
      matchFormRules: {
        matchTitle: [{ required: true, message: "请填写比赛名称" }],
        matchDate: [{ required: true, message: "请选择比赛时间" }],
        level: [{ required: true, message: "请选择比赛等级" }],
      },
    };
  },
  mounted() {
    this.getMatchListByUserId();
  },
  methods: {
    // 添加新的比赛信息
    addMatchEvent() {
      this.matchModelVisible = true;
      this.modalTitle = "新增比赛信息";
      this.matchForm = {
        matchId: "",
        matchTitle: "",
        hostName: this.$store.state.userInfo.userName,
        hostId: this.$store.state.userInfo.userId,
        partName: "",
        teacherName: "",
        level: "",
        matchDate: "",
        matchImg: "",
      };
    },
    // 点击编辑按钮时候调用的方法
    editEvent(row) {
      this.matchModelVisible = true;
      this.matchForm = row;
      this.modalTitle = "修改比赛信息";
    },
    // 根据当前登录用户的ID获取比赛数据
    getMatchListByUserId() {
      let this_ = this;
      getMatchListByUserId(this_.$store.state.userInfo.userId)
        .then((response) => {
          this_.matchList = response.data;
        })
        .catch((error) => {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    // 点击删除按钮的时候调用的方法
    removeEvent(row) {
      let this_ = this;
      this_.$XModal.confirm("您确定要删除论文吗？").then((type) => {
        if (type == "cancel") {
        } else {
          delMatch({ matchId: row.matchId })
            .then((response) => {
              this_.$message({
                message: "删除成功",
                type: "success",
              });
            })
            .catch((error) => {
              this_.message({
                message: error,
                type: "error",
              });
            });
        }
      });
    },
    // 图片文件改变
    fileChange(event) {
      let this_ = this;
      const files = event.target.files[0];
      this.matchForm.matchImg = files[0];
      var reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function (e) {
        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
        var imgcode = e.target.result;
        this_.matchForm.matchImg = imgcode;
      };
    },
    // 提交事件
    submitEvent() {
      let this_ = this;
      this_.$refs.matchForm.validate((result) => {
        if (result === undefined) {
          if (this_.modalTitle === "新增比赛信息") {
            addMatchInfo(this_.matchForm)
              .then((response) => {
                this_.$message({
                  message: "新增项目成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          } else {
            modifyMatchInfo(this_.matchForm)
              .then((response) => {
                this_.$message({
                  message: "项目修改成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-match-manage {
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  .operation-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .operation-table {
    height: calc(100vh - 210px);
  }
}
</style>
