import Vue from "vue";
import Vuex from "vuex";
import VuexPerisitence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPerisitence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    userInfo: {},
    adminMenu: "",
  },
  mutations: {
    SET_USERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    DEL_USERINFO(state) {
      state.userInfo = {};
    },
    SET_ADMINMENU(state, menu) {
      state.adminMenu = menu;
    },
    DEL_ADMINMENU(state) {
      state.adminMenu = "";
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});
