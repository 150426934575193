<template>
  <div class="project-manage">
    <admin-project-manage
      v-if="$store.state.userInfo.userId == 4"
    ></admin-project-manage>
    <user-project-manage v-else></user-project-manage>
  </div>
</template>

<script>
import UserProjectManage from "./ordinaryUser/UserProjectManage.vue";
import AdminProjectMange from "./systemAdmin/AdminProjectManage.vue";

export default {
  data() {
    return {};
  },
  components: {
    "user-project-manage": UserProjectManage,
    "admin-project-manage": AdminProjectMange,
  },
};
</script>

<style lang="scss" scope>
.project-manage {
  height: 100%;
  padding: 10px;
}
</style>
