<template>
  <div class="teacher">
    <div class="header_ig">
      <img src="http://www.hrbnu.edu.cn/images/dsxxjy100.jpg" alt="" />
    </div>
    <div class="container">
      <div
        class="teacher-card"
        v-for="(item, index) in teacherList"
        :key="index"
      >
        <img :src="item.photo" alt="免冠照" />
        <span>{{ item.teacherName }}</span>
        <el-button type="primary" @click="showDetail(item)">查看详情</el-button>
      </div>
    </div>
    <el-dialog
      :title="currentTeacher.teacherName"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item name="1">
          <template slot="title" class="collapse-item">
            <div class="collapse-item-title">基本介绍</div>
          </template>
          <span
            >姓名：<el-tag type="small">{{
              currentTeacher.teacherName
            }}</el-tag></span
          >
          <span
            >性别：<el-tag type="small">{{
              currentTeacher.gender
            }}</el-tag></span
          >
          <span
            >工作单位：<el-tag type="small">{{
              currentTeacher.unit
            }}</el-tag></span
          >
          <span
            >邮箱：<el-tag type="small">{{
              currentTeacher.phoneUnmber
            }}</el-tag></span
          >
        </el-collapse-item>
        <el-collapse-item name="2">
          <template slot="title" class="collapse-item">
            <div class="collapse-item-title">个人简介</div>
          </template>
          <pre style="white-space: pre-wrap; word-wrap: break-word">{{
            currentTeacher.teaching
          }}</pre>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title" class="collapse-item">
            <div class="collapse-item-title">论文情况</div>
          </template>
          <pre style="white-space: pre-wrap; word-wrap: break-word">{{
            currentTeacher.thesis
          }}</pre>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template slot="title" class="collapse-item">
            <div class="collapse-item-title">专著与教材情况</div>
          </template>
          <pre style="white-space: pre-wrap; word-wrap: break-word">{{
            currentTeacher.monograph
          }}</pre>
        </el-collapse-item>
        <el-collapse-item name="5">
          <template slot="title" class="collapse-item">
            <div class="collapse-item-title">项目情况</div>
          </template>
          <pre style="white-space: pre-wrap; word-wrap: break-word">{{
            currentTeacher.project
          }}</pre>
        </el-collapse-item>
        <el-collapse-item name="6">
          <template slot="title" class="collapse-item">
            <div class="collapse-item-title">专利情况</div>
          </template>
          <pre style="white-space: pre-wrap; word-wrap: break-word">{{
            currentTeacher.patent
          }}</pre>
        </el-collapse-item>
        <el-collapse-item name="7">
          <template slot="title" class="collapse-item">
            <div class="collapse-item-title">获奖情况</div>
          </template>
          <pre style="white-space: pre-wrap; word-wrap: break-word">{{
            currentTeacher.prize
          }}</pre>
        </el-collapse-item>
      </el-collapse>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getTeacherList } from "../../api/teacher";
export default {
  data() {
    return {
      teacherList: [],
      dialogVisible: false,
      currentTeacher: {},
      activeNames: "1",
    };
  },
  created() {
    this.getTeacherList();
  },
  methods: {
    getTeacherList() {
      let this_ = this;
      getTeacherList()
        .then(function (response) {
          this_.teacherList = response.data;
        })
        .catch(function (error) {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    //
    showDetail(person) {
      this.dialogVisible = true;
      this.currentTeacher = person;
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 1300px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #eee;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 900px;
    .teacher-card {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      // align-items: center;
      width: 200px;
      height: 380px;
      margin-top: 15px;
      span {
        font-size: 16px;
      }
      img {
        display: block;
        width: 200px;
        height: 280px;
      }
    }
  }

  .collapse-item-title {
    font-size: 16px;
    font-weight: bold;
    color: #224fac;
  }
  .el-collapse-item {
    text-align: left;
    span {
      margin-left: 3px;
    }
    p {
      text-indent: 2em;
    }
    .el-tag {
      white-space: normal;
      height: auto;
      margin: 5px;
    }
  }
}
</style>
