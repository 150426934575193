import request from "../https/request";

export function getMatchList() {
  return request({
    url: "/matchs",
    method: "get",
  });
}

export function getMatchListByUserId(userId) {
  return request({
    url: "/matchs/" + userId,
    method: "get",
  });
}

export function delMatch(data) {
  return request({
    url: "/matchs",
    method: "delete",
    params: data
  });
}

export function addMatchInfo(data) {
  return request({
    url: "/matchs",
    method: "post",
    data,
  });
}

export function modifyMatchInfo(data) {
  return request({
    url: "/matchs/modify",
    method: "post",
    data
  });
}
