import request from "../https/request";

export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data,
  });
}

export function getUserInfo(data) {
  return request({
    url: "/user/getInfo",
    method: "get",
    params: data
  });
}

// 获取所有用户配置信息
export function getUserList() {
  return request({
    url: "/user",
    method: "get",
  });
}

// 注册新用户
export function registerUser(data) {
  return request({
    url: "/user",
    method: "post",
    data,
  });
}

export function modifyUserInfo(data) {
  return request({
    url: "/user",
    method: "put",
    data,
  });
}

export function delUserInfo(data) {
  return request({
    url: "/user",
    method: "delete",
    params: data,
  });
}
