<template>
  <div class="user-active-manage">
    <el-row class="operation-row">
      <el-button type="primary" size="mini" @click="addActiveEvent"
        >添加活动信息</el-button
      >
    </el-row>
    <el-divider></el-divider>
    <el-row class="operation-table">
      <!-- 添加具有编辑功能的表格 -->
      <vxe-table
        align="center"
        border
        stripe
        :row-config="{ isCurrent: true, isHover: true }"
        :data="activeList"
        height="100%"
      >
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column field="activeTitle" title="活动主题"></vxe-column>
        <vxe-column field="activeTime" title="活动时间"></vxe-column>
        <vxe-column field="activeAddress" title="活动地点"></vxe-column>
        <vxe-column field="initiator" title="活动发起人"></vxe-column>
        <vxe-column field="activeImg" title="活动图片" width="250">
          <template #default="{ row }">
            <img
              :src="row.activeImg"
              alt=""
              style="width: 200px; height: 100px"
            />
          </template>
        </vxe-column>
        <vxe-column title="操作" width="100">
          <template #default="{ row }">
            <vxe-button
              type="text"
              icon="fa fa-edit"
              @click="editEvent(row)"
            ></vxe-button>
            <vxe-button
              type="text"
              icon="fa fa-trash-o"
              @click="removeEvent(row)"
            ></vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </el-row>
    <vxe-modal
      v-model="activeModelVisible"
      width="600"
      height="400"
      :title="modalTitle"
    >
      <vxe-form
        :title-width="100"
        title-align="right"
        :data="activeForm"
        :rules="activeFormRules"
        @submit="submitEvent"
        ref="activeForm"
      >
        <!-- 活动编号 -->
        <vxe-form-item
                title="活动编号"
                field="activeId"
                :item-render="{}"
                :span="24"
        >
          <template #default="{ data }">
            <vxe-input
                v-model="data.activeId"
                placeholder="请输入活动编号"
                clearable
            ></vxe-input>
          </template>
        </vxe-form-item>

        <!-- 活动名称 -->
        <vxe-form-item
          title="活动名称"
          field="activeTitle"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input
              v-model="data.activeTitle"
              placeholder="请输入活动名称"
              clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 活动时间 -->
        <vxe-form-item
          title="活动时间"
          field="activeTime"
          :item-render="{}"
          :span="12"
        >
          <template #default="{ data }">
            <vxe-input type="date" v-model="data.activeTime"></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 活动地点 -->
        <vxe-form-item
          title="活动地点"
          field="activeAddress"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input
              v-model="data.activeAddress"
              placeholder="请输入活动地点"
              clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <!-- 活动图片 -->
        <vxe-form-item title="活动图片" field="activeImg">
          <template #default="{}">
            <input type="file" @change="fileChange($event)" />
          </template>
        </vxe-form-item>
        <!-- 提交 -->
        <vxe-form-item align="center" span="24" :item-render="{}">
          <template>
            <vxe-button type="submit" status="primary">提交</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </div>
  <!-- 编辑弹窗 -->
</template>

<script>
import {
  getActiveListByUserId,
  delActive,
  addActive,
  modifyActive,
} from "@/api/active.js";
export default {
  data() {
    return {
      activeList: [],
      activeModelVisible: false,
      modalTitle: "",
      activeForm: {
        activeId: "",
        activeTitle: "",
        activeTime: "",
        activeAddress: "",
        initiator: this.$store.state.userInfo.userName,
        initiatorId: this.$store.state.userInfo.userId,
        activeImg: "",
      },
      activeFormRules: {},
    };
  },
  beforeMount() {
    this.getActiveListByUserId();
  },
  methods: {
    // 获取活动信息（发起人）
    getActiveListByUserId() {
      let this_ = this;
      getActiveListByUserId(this_.$store.state.userInfo.userId)
        .then((response) => {
          this_.activeList = response.data;
        })
        .catch((error) => {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    // 点击添加活动信息的按钮
    addActiveEvent() {
      this.activeModelVisible = true;
      this.modalTitle = "新增活动信息";
      this.activeForm = {
        activeId: "",
        activeTitle: "",
        activeTime: "",
        activeAddress: "",
        initiator: this.$store.state.userInfo.userName,
        initiatorId: this.$store.state.userInfo.userId,
        activeImg: "",
      };
    },
    // 编辑活动信息
    editEvent(row) {
      this.activeModelVisible = true;
      this.modalTitle = "修改活动信息";
      this.activeForm = row;
    },
    // 删除活动信息
    removeEvent(row) {
      let this_ = this;
      this_.$XModal.confirm("您确定要删除活动信息吗？").then((type) => {
        if (type == "cancel") {
        } else {
          delActive({ activeId: row.activeId })
            .then((response) => {
              this_.$message({
                message: "删除成功",
                type: "success",
              });
            })
            .catch((error) => {
              this_.message({
                message: error,
                type: "error",
              });
            });
        }
      });
    },
    // 提交按钮事件
    submitEvent() {
      let this_ = this;
      this_.$refs.activeForm.validate((result) => {
        if (result === undefined) {
          if (this_.modalTitle === "新增活动信息") {
            addActive(this_.activeForm)
              .then((response) => {
                this_.$message({
                  message: "新增活动信息成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          } else {
            modifyActive(this_.activeForm)
              .then((response) => {
                this_.$message({
                  message: "修改活动信息成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          }
        }
      });
    },
    // 文件选择
    fileChange(event) {
      let this_ = this;
      const files = event.target.files[0];
      this.activeForm.activeImg = files[0];
      var reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function (e) {
        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
        var imgcode = e.target.result;
        this_.activeForm.activeImg = imgcode;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.user-active-manage {
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  .operation-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .operation-table {
    height: calc(100vh - 210px);
  }
}
</style>
