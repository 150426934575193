import axios from "axios";
import { getCookie, setCookie } from "../utils/cookie";
// axios.defaults.headers.post["Content-Type"] = "application/x-www-urlencoded";
const service = axios.create({
  baseURL: process.env.VUE_APP_baseURL,
  timeout: 5000,
});

// 请求拦截器配置 // 可不配置
service.interceptors.request.use(
  (config) => {
    if (config.url == "/user") {
      if (
        config.method == "put" ||
        config.method == "delete" ||
        config.method == "post"
      ) {
        let roleId = parseInt(JSON.parse(localStorage.vuex).userInfo.roleId);
        if (roleId != 1) {
          return Promise.reject("对不起！您没有操作权限。");
        }
      }
    }
    if (config.url == "/user/getInfo") {
      config.headers.common["token"] = getCookie("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // 响应拦截器配置 // 可不配置
    let url = response.config.url;
    if (url != "/user/login" && url !== "/user/getInfo") {
      return response.data;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
