import { render, staticRenderFns } from "./UserActiveManage.vue?vue&type=template&id=072e06b5&scoped=true"
import script from "./UserActiveManage.vue?vue&type=script&lang=js"
export * from "./UserActiveManage.vue?vue&type=script&lang=js"
import style0 from "./UserActiveManage.vue?vue&type=style&index=0&id=072e06b5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072e06b5",
  null
  
)

export default component.exports