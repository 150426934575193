<template>
  <div class="admin-patent-mamage"></div>
</template>

<script>
export default {
  data() {},
};
</script>

<style lang="scss" scoped></style>
