<template>
  <div class="student">
    <div class="content">
      <div class="headerimg">
        <img src="http://www.hrbnu.edu.cn/images/dsxxjy100.jpg" alt="" />
      </div>
      <div class="studen-info">
        <el-tabs v-model="activeName" @tab-click="tabclick">
          <el-tab-pane label="研究生" name="first">
            <div
              class="student-card"
              v-for="(item, index) in masterList"
              :key="index"
            >
              <img :src="item.photo" alt="免冠照" />
              <span
                >{{ item.name }}
                <el-tag size="mini" v-if="item.isGraduated === 1" type="success"
                  >毕业</el-tag
                >
                <el-tag size="mini" v-else>在读</el-tag></span
              >
              <el-button type="primary" @click="showDetail(item)"
                >查看详情</el-button
              >
            </div></el-tab-pane
          >
          <el-tab-pane label="本科生" name="second">
            <div
              class="student-card"
              v-for="(item, index) in bachelorList"
              :key="index"
            >
              <img :src="item.photo" alt="免冠照" />
              <span
                >{{ item.name }}
                <el-tag size="mini" v-if="item.isGraduated === 1" type="success"
                  >毕业</el-tag
                >
                <el-tag size="mini" v-else>在读</el-tag>
              </span>

              <el-button type="primary" @click="showDetail(item)"
                >查看详情</el-button
              >
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog
        :title="currentStudent.name"
        :visible.sync="dialogVisible"
        width="50%"
      >
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item name="1">
            <template slot="title" class="collapse-item">
              <div class="collapse-item-title">基本介绍</div>
            </template>
            <span
              >姓名：<el-tag type="small">{{
                currentStudent.name
              }}</el-tag></span
            >
            <span
              >性别：<el-tag type="small">{{
                currentStudent.gender
              }}</el-tag></span
            >
            <span
              >年龄：<el-tag type="small">{{
                currentStudent.age
              }}</el-tag></span
            >
            <span
              >工作或学习单位：<el-tag type="small">{{
                currentStudent.unit
              }}</el-tag></span
            >
            <span
              >联系方式：<el-tag type="small">{{
                currentStudent.phoneUnmber
              }}</el-tag></span
            >
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title" class="collapse-item">
              <div class="collapse-item-title">主修课程情况</div>
            </template>
            <pre style="white-space: pre-wrap; word-wrap: break-word">{{
              currentStudent.majorCourse
            }}</pre>
          </el-collapse-item>
          <el-collapse-item name="3">
            <template slot="title" class="collapse-item">
              <div class="collapse-item-title">论文情况</div>
            </template>
            <pre style="white-space: pre-wrap; word-wrap: break-word">{{
              currentStudent.thesis
            }}</pre>
          </el-collapse-item>
          <el-collapse-item name="4">
            <template slot="title" class="collapse-item">
              <div class="collapse-item-title">实习（工作）经历</div>
            </template>
            <pre style="white-space: pre-wrap; word-wrap: break-word">{{
              currentStudent.experiment
            }}</pre>
          </el-collapse-item>
          <el-collapse-item name="5">
            <template slot="title" class="collapse-item">
              <div class="collapse-item-title">项目情况</div>
            </template>
            <pre style="white-space: pre-wrap; word-wrap: break-word">{{
              currentStudent.project
            }}</pre>
          </el-collapse-item>
          <el-collapse-item name="6">
            <template slot="title" class="collapse-item">
              <div class="collapse-item-title">专利情况</div>
            </template>
            <pre style="white-space: pre-wrap; word-wrap: break-word">{{
              currentStudent.patent
            }}</pre>
          </el-collapse-item>
          <el-collapse-item name="7">
            <template slot="title" class="collapse-item">
              <div class="collapse-item-title">获奖情况</div>
            </template>
            <pre style="white-space: pre-wrap; word-wrap: break-word">{{
              currentStudent.prize
            }}</pre>
          </el-collapse-item>
        </el-collapse>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getMasterList } from "../../api/master";
import { getBachelorList } from "../../api/bachelor";
export default {
  data() {
    return {
      masterList: [],
      bachelorList: [],
      activeName: "first",
      activeNames: "1",
      currentStudent: {},
      dialogVisible: false,
    };
  },
  created() {
    this.getMasterList();
    this.getBachelorList();
  },
  methods: {
    getMasterList() {
      let this_ = this;
      getMasterList()
        .then(function (response) {
          this_.masterList = response.data;
        })
        .catch(function (error) {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    getBachelorList() {
      let this_ = this;
      getBachelorList()
        .then(function (response) {
          this_.bachelorList = response.data;
        })
        .catch(function (error) {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    tabclick(tab, event) {
      let this_ = this;
      switch (parseInt(tab.index)) {
        case 0:
          this_.getMasterList();
          break;
        case 1:
          this_.getBachelorList();
          break;
        default:
          this_.$message({
            message: "NetWork Error!",
            type: "error",
          });
          break;
      }
    },
    showDetail(person) {
      this.currentStudent = person;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.student {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 1300px;
  padding: 15px 0;
  background-color: #eee;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 900px;
    .studen-info {
      .el-tab-pane {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 900px;
        .student-card {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          width: 200px;
          height: 380px;
          span {
            font-size: 16px;
          }
        }
      }
    }
  }

  .collapse-item-title {
    font-size: 16px;
    font-weight: bold;
    color: #224fac;
  }
  .el-collapse-item {
    text-align: left;
    span {
      margin-left: 3px;
    }
    p {
      text-indent: 2em;
    }
    .el-tag {
      white-space: normal;
      height: auto;
      margin: 5px;
    }
  }
}
</style>
