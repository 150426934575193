<template>
  <div class="active-manage">
    <admin-active-manage
      v-if="$store.state.userInfo.roleId === 4"
    ></admin-active-manage>
    <user-active-manage v-else></user-active-manage>
  </div>
</template>

<script>
import UserActiveManage from "./ordinaryUser/UserActiveManage.vue";
import AdminActiveManage from "./systemAdmin/AdminActiveManage.vue";

export default {
  data() {
    return {};
  },
  components: {
    "user-active-manage": UserActiveManage,
    "admin-active-manage": AdminActiveManage,
  },
};
</script>

<style lang="scss" scoped>
.active-manage {
  height: 100%;
  padding: 10px;
}
</style>
