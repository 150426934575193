import request from "../https/request";

// 获取所有论文  http://localhost:port/apis/thesis
export function getThesisList() {
  return request({
    url: "/thesis",
    method: "get",
  });
}

// 根据用户ID获取论文 http://localhost:port/apis/thesis/{{id}}
export function getThesisListByUserId(id) {
  return request({
    url: "/thesis/" + id,
    method: "get",
  });
}

// 根据论文的ID修改论文内容
export function modifyThesisById(data) {
  return request({
    url: "/thesis",
    method: "put",
    data,
  });
}

export function addThesis(data) {
  return request({
    url: "/thesis",
    method: "post",
    data,
  });
}

export function delThesis(data) {
  return request({
    url: "/thesis",
    method: "delete",
    params: data
  });
}
