import request from "../https/request";

export function getPatentList() {
  return request({
    url: "/patent",
    method: "get",
  });
}

export function getPatentListByUserId(userId) {
  return request({
    url: "/patent/" + userId,
    method: "get",
  });
}

export function delPatent(data) {
  return request({
    url: "/patent",
    method: "delete",
    params: data
  });
}

export function addPatent(data) {
  return request({
    url: "/patent",
    method: "post",
    data,
  });
}

export function modifyPatent(data) {
  return request({
    url: "/patent/modify",
    method: "post",
    data
  });
}
