<template>
  <div class="index">
    <div class="pictures-con">
      <div class="pictures">
        <el-carousel :interval="4000" type="card" height="200px">
          <el-carousel-item v-for="(item, index) in pics" :key="index">
            <img :src="item.image" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="menu-con">
      <div class="menu">
        <el-tabs @tab-click="tabclick">
          <el-tab-pane label="论文">
            <thesis v-bind:thesislist="thesislist"></thesis>
          </el-tab-pane>
          <el-tab-pane label="项目">
            <project v-bind:projectList="projectList"></project
          ></el-tab-pane>
          <el-tab-pane label="比赛">
            <match-card :matchList="matchList"></match-card>
          </el-tab-pane>
          <el-tab-pane label="专利">
            <patent-card :patentList="patentList"></patent-card>
          </el-tab-pane>
          <el-tab-pane label="活动">
            <activity-card :activityList="activityList"></activity-card>
          </el-tab-pane>
          <el-tab-pane label="科研资料">
            <Metadata :metadataList="metadataList"></Metadata>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { getLabPic } from "../../api/loopimage";
import { getThesisList } from "../../api/thesis";
import { getProjectList } from "../../api/project";
import { getMatchList } from "../../api/macths";
import { getPatentList } from "../../api/patent";
import { getActivityList } from "../../api/active";
import { getMetadataList } from "../../api/metadata";
import Thesis from "../Index/Thesis.vue";
import Project from "../Index/Project.vue";
import MatchCard from "../Index/MatchCard.vue";
import PatentCard from "../Index/PatentCard.vue";
import ActivityCard from "../Index/ActivityCard.vue";
import Metadata from "../Index/Metadata.vue";

export default {
  components: { Thesis, Project, MatchCard, PatentCard, ActivityCard, Metadata },
  data() {
    return {
      pics: [],
      menu_item: [
        {
          text: "论文",
          path: "",
          classname: "#icon-EC_gerenwengao-xueshulunwen",
        },
        {
          text: "项目",
          path: "",
          classname: "#icon-a-tianjiaxiangmuxinxiangmu",
        },
        {
          text: "比赛",
          path: "",
          classname: "#icon-bisaiguanli",
        },
        {
          text: "专利",
          path: "",
          classname: "#icon-zhuanlichengguo",
        },
        {
          text: "活动",
          path: "",
          classname: "#icon-huodong",
        },
        {
          text: "科研资料",
          path: "",
          classname: "#icon-jiyinyanjiu",
        },
      ],
      // 论文列表
      thesislist: [],
      projectList: [],
      matchList: [],
      patentList: [],
      activityList: [],
      metadataList: [],
    };
  },
  created() {
    this.getLabPic();
    this.getThesisList();
  },
  methods: {
    getLabPic() {
      let this_ = this;
      getLabPic("/getLabPic", {})
        .then(function (response) {
          this_.pics = response.data;
        })
        .catch(function (error) {});
    },
    // 获取论文列表的方法
    getThesisList() {
      let this_ = this;
      getThesisList()
        .then(function (response) {
          this_.thesislist = response.data;
        })
        .catch(function (error) {});
    },
    //
    getProjectList() {
      let this_ = this;
      getProjectList()
        .then(function (response) {
          this_.projectList = response.data;
        })
        .catch(function (error) {});
    },
    //
    getMatchList() {
      let this_ = this;
      getMatchList()
        .then(function (response) {
          this_.matchList = response.data;
        })
        .catch(function (error) {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    //
    getPatentList() {
      let this_ = this;
      getPatentList()
        .then(function (response) {
          this_.patentList = response.data;
        })
        .catch(function (error) {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    //
    getActivityList() {
      let this_ = this;
      getActivityList()
        .then(function (response) {
          this_.activityList = response.data;
        })
        .catch(function (error) {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    // 获取研究资料（发起人）
    getMetadataList() {
        let this_ = this;
        getMetadataList()
            .then((response) => {
                this_.metadataList = response.data;
            })
            .catch((err) => {
                this_.$message({
                    message: err,
                    type: "error",
                });
            });
    },
    tabclick(tab, event) {
      switch (parseInt(tab.index)) {
        case 0:
          this.getThesisList();
          break;
        case 1:
          this.getProjectList();
          break;
        case 2:
          this.getMatchList();
          break;
        case 3:
          this.getPatentList();
          break;
        case 4:
          this.getActivityList();
          break;
        case 5:
          this.getMetadataList();
          break;
        default:
          this.$message({
            message: "请求数据有误，请稍后再试！",
            type: "error",
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.index {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
  .pictures-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1300px;
    background-color: #eee;
    .pictures {
      width: 900px;
    }
  }
  .menu-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1300px;
    background-color: #eee;
    padding: 10px;
    .menu {
      width: 1100px;
    }
  }
}

.el-tabs {
  .el-tabs__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .el-tab-pane {
    width: 1000px;
  }
  .el-tabs__item {
    font-size: 16px;
    font-weight: 600;
  }
}

.el-carousel {
  padding-top: 15px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
