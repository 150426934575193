<template>
  <div class="manage-welcome">
    <div class="art-text">
      <h1>welcome!</h1>
      <h3>
        欢迎使用哈尔滨师范大学计算机科学与信息工程学院智能软件技术实验室后台管理系统
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.manage-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#790000, #fff);
  .art-text {
    h1,
    h3 {
      font-size: 100px;
      color: #fff;
      text-shadow: 5px 5px 5px #000;
      font-style: italic;
    }
    h3 {
      font-size: 30px;
    }
  }
}
</style>
