import request from "../https/request.js";

export function getTeacherList() {
  return request({
    url: "/teacher",
    method: "get",
  });
}

// 根据用户的ID获取教师的信息
export function getTeacherInfoById(id) {
  return request({
    url: "/teacher/" + id,
    method: "get",
  });
}

// 根据教师ID修改教师的信息
export function modifyTeacherInfoById(data) {
    return request({
        url: "/teacher/modify",
        method: "post",
        data,
    });
}

// export function delTeacher(data) {
//     return request({
//         url: "/teacher",
//         method: "delete",
//         params: data
//     });
// }
