<template>
    <div class="metadata">
        <el-card
                shadow="hover"
                class="metadata-card"
                v-for="(item, index) in metadataList"
                :key="index"
        >
            <el-descriptions
                    :title="item.dataTitle"
                    border
                    :column="2"
                    size="mini"
                    colon
            >
                <el-descriptions-item
                        label="完成人"
                        :contentStyle="{ width: '100px' }"
                        :labelStyle="{ 'font-weight': 'bold', width: '60px' }"
                >
                    <el-tag size="small">{{ item.dataOwner }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item
                        label="资料内容"
                        :contentStyle="{ width: '200px' }"
                        :labelStyle="{ 'font-weight': 'bold', width: '60px' }"
                >
                    <el-tag size="small">{{ item.dataContent }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item
                        label="资料网址"
                        :contentStyle="{ width: '500px' }"
                        :labelStyle="{ 'font-weight': 'bold', width: '60px' }"
                >
                    <a :href="item.dataurl" >{{ item.dataUrl }}</a>
                </el-descriptions-item>

            </el-descriptions>
        </el-card>
    </div>
</template>

<script>
export default {
    props: ["metadataList"],
    data() {
        return {};
    },
    // mounted(){
    //     console.log(this.metadataList);
    // }
}
</script>
<style lang="scss">
.metadata-card {
    margin-top: 10px;
    border-radius: 12px !important;
    .my-label {
        width: 8em;
        padding: 0;
    }
    .author-tag {
        margin-left: 5px;
    }
}
</style>