import request from "../https/request.js";

export function getMasterList() {
  return request({
    url: "/master",
    method: "get",
  });
}

// 根据用户 ID获取研究生信息的接口
export function getMasterInfoById(id) {
  return request({
    url: "/master/" + id,
    method: "get",
  });
}

// 根据研究生的ID个人信息
export function modifyMasterInfoById(data) {
  return request({
    url: "/master/modify",
    method: "post",
    data,
  });
}
