<template>
  <div class="notfound">
    <div class="notfound-image">
      <img src="../assets/images/404.png" alt="" />
    </div>
    <div class="title">
      <h2>页面走丢了...</h2>
      <h4>远方的朋友你好！非常抱歉，您所请求的页面不存在！</h4>
      <h4>请仔细检查您输入的网址是否正确。</h4>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.notfound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #7395ff;
}
.title {
  color: #fff;
  h2 {
    font-size: 36px;
    letter-spacing: 4px;
    text-align: center;
    line-height: 120px;
  }
  h4 {
    font-size: 18px;
    line-height: 38px;
    text-align: center;
  }
}
</style>
