<template>
  <div class="project">
    <div class="card" v-for="(item, index) in projectList" :key="index">
      <div class="up-card">
        <img :src="item.projectImg" />
      </div>
      <div class="descript">{{ item.projectTitle }}</div>
      <div class="content" style="padding-left: 15px; text-align: left">
        <ul>
          <li><b>主持人：</b>{{ item.hostName }}</li>
          <li>
            <b>参与人：</b>
            {{ item.partName }}
          </li>
          <li><b>级别：</b>{{ item.level }}</li>
          <li><b>日期：</b>{{ item.projectDate }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["projectList"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.project {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  position: relative;
  margin: 10px;
  width: 400px;
  height: 200px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 3px 3px 3px #666;
  overflow: hidden;
  .up-card {
    transition-duration: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 200px;
    z-index: 2;
    img {
      transition-duration: 0.3s;
      position: absolute;
      top: 0;
      left: 0;
      width: 400px;
      height: 200px;
      border-radius: 12px;
    }
  }
  .descript {
    transition-duration: 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    font-size: 18px;
    color: #fff;
    text-align: center;
    z-index: 3;
  }
  .content {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 10px;
    padding-right: 10px;
    z-index: 1;
    ul > li {
      width: 250px;
      height: auto;
      overflow: hidden;
      white-space: wrap;
      margin-bottom: 5px;
    }
  }
}
// 覆盖后的变化
.card:hover {
  position: relative;
  margin: 10px;
  width: 400px;
  height: 200px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 3px 3px 3px #666;
  overflow: hidden;
  .up-card {
    transition-duration: 0.3s;
    position: relative;
    top: 5px;
    left: 5px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 3px 3px 3px #333;
    z-index: 2;
    img {
      transition-duration: 0.3s;
      position: absolute;
      top: 0;
      left: -50px;
      width: 200px;
      height: 100px;
      border-radius: 12px;
    }
  }
  .descript {
    transition-duration: 0.3s;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    font-size: 18px;
    color: #000;
    text-align: center;
    z-index: 3;
  }
  .content {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 10px;
    padding-right: 10px;
    z-index: 1;
    ul > li {
      width: 250px;
      height: auto;
      overflow: hidden;
      white-space: wrap;
      margin-bottom: 5px;
    }
  }
}
</style>
