<template>
  <div class="user-project-manage">
    <el-row class="operation-row">
      <el-button type="primary" size="mini" @click="addProjectEvent"
        >新增项目</el-button
      >
    </el-row>
    <el-divider></el-divider>
    <el-row class="operation-table">
      <vxe-table
        align="center"
        border
        stripe
        :row-config="{ isCurrent: true, isHover: true }"
        :data="projectList"
        height="100%"
      >
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column field="projectTitle" title="项目名称"></vxe-column>
        <vxe-column field="hostName" title="主持人"></vxe-column>
        <vxe-column field="partName" title="参与人"></vxe-column>
        <vxe-column field="projectImg" title="项目图片" width="250">
          <template #default="{ row }">
            <img
              :src="row.projectImg"
              alt=""
              style="width: 200px; height: 100px"
            />
          </template>
        </vxe-column>
        <vxe-column field="level" title="项目级别" width="100">
          <template #default="{ row }">
            <el-tag>{{ row.level }}</el-tag>
          </template>
        </vxe-column>
        <vxe-column
          field="projectDate"
          title="项目完结时间"
          width="100"
        ></vxe-column>
        <vxe-column title="操作" width="100">
          <template #default="{ row }">
            <vxe-button
              type="text"
              icon="fa fa-edit"
              @click="editEvent(row)"
            ></vxe-button>
            <vxe-button
              type="text"
              icon="fa fa-trash-o"
              @click="removeEvent(row)"
            ></vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </el-row>
    <!-- 项目表单弹窗 -->
    <vxe-modal
      v-model="projectModelVisible"
      width="600"
      height="400"
      :title="modalTitle"
    >
      <vxe-form
        :title-width="100"
        title-align="right"
        :data="projcetForm"
        :rules="projcetFormRules"
        @submit="submitEvent"
        ref="projcetForm"
      >

        <vxe-form-item
                title="项目编号"
                field="projectId"
                :item-render="{}"
                :span="24"
        >
          <template #default="{ data }">
            <vxe-input
                    v-model="data.projectId"
                    placeholder="请输入项目编号"
                    clearable
            ></vxe-input>
          </template>
        </vxe-form-item>

        <vxe-form-item
          title="项目名称"
          field="projectTitle"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input
              v-model="data.projectTitle"
              placeholder="请输入项目名称"
              clearable
            ></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item
          title="项目主持人"
          field="hostName"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input v-model="data.hostName" disabled></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item
          title="其他参与人"
          field="partName"
          :item-render="{}"
          :span="24"
        >
          <template #default="{ data }">
            <vxe-input v-model="data.partName"></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item
          title="项目完成时间"
          field="projectDate"
          :item-render="{}"
          :span="12"
        >
          <template #default="{ data }">
            <vxe-input type="date" v-model="data.projectDate"></vxe-input>
          </template>
        </vxe-form-item>
        <vxe-form-item
          title="项目级别"
          field="level"
          :item-render="{}"
          :span="12"
        >
          <template #default="{ data }">
            <vxe-select v-model="data.level">
              <vxe-option value="校级" label="校级"></vxe-option>
              <vxe-option value="省级" label="省级"></vxe-option>
              <vxe-option value="国家级" label="国家级"></vxe-option>
            </vxe-select>
          </template>
        </vxe-form-item>
        <vxe-form-item title="项目图片">
          <template #default="{}">
            <input type="file" @change="fileChange($event)" />
          </template>
        </vxe-form-item>
        <vxe-form-item align="center" span="24" :item-render="{}">
          <template>
            <vxe-button type="submit" status="primary">提交</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </div>
</template>

<script>
import {
  getProjectListById,
  addProject,
  modifyProject,
  delProject,
} from "../../../api/project";

export default {
  data() {
    return {
      projectList: [],
      //
      modalTitle: "",
      projectModelVisible: false,
      projcetForm: {
        projectId: "",
        projectTitle: "",
        level: "",
        hostName: "",
        partName: "",
        hostId: "",
        projectDate: "",
        projectImg: "",
      },
      //
      projcetFormRules: {
        projectId: [{ required: true, message: "请填写项目编号" }],
        projectTitle: [{ required: true, message: "请填写项目名称" }],
        projectDate: [{ required: true, message: "请选择项目完成事件" }],
        level: [{ required: true, message: "选择项目的等级" }],
      },
    };
  },
  mounted() {
    this.getProjectListById();
  },
  methods: {
    // 根据用户Id获取项目列表的函数
    getProjectListById() {
      let this_ = this;
      getProjectListById(this_.$store.state.userInfo.userId)
        .then((response) => {
          this_.projectList = response.data;
        })
        .catch((error) => {
          this_.$message({
            message: error,
            type: "error",
          });
        });
    },
    // 点击新增项目调用的函数
    addProjectEvent() {
      this.projectModelVisible = true;
      this.modalTitle = "新增项目";
      this.projcetForm = {
        projectId: "",
        projectTitle: "",
        level: "",
        hostName: this.$store.state.userInfo.userName,
        hostId: this.$store.state.userInfo.userId,
        partName: "",
        projectDate: "",
        projectImg: "",
      };
    },
    //
    editEvent(row) {
      this.projectModelVisible = true;
      this.modalTitle = "编辑项目";
      this.projcetForm = row;
    },
    removeEvent(row) {
      let this_ = this;
      this_.$XModal.confirm("您确定要删除论文吗？").then((type) => {
        if (type == "cancel") {
        } else {
          delProject({ projectId: row.projectId })
            .then((response) => {
              this_.$message({
                message: "删除成功",
                type: "success",
              });
            })
            .catch((error) => {
              this_.message({
                message: error,
                type: "error",
              });
            });
        }
      });
    },
    // 选取的文件发生改变的时候调用的数据
    fileChange(event) {
      let this_ = this;
      const files = event.target.files[0];
      this.projcetForm.projectImg = files[0];
      var reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function (e) {
        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
        var imgcode = e.target.result;
        this_.projcetForm.projectImg = imgcode;
      };
    },
    submitEvent() {
      let this_ = this;
      console.log(this_.$refs.projcetForm);
      this_.$refs.projcetForm.validate((result) => {
        if (result === undefined) {
          if (this_.modalTitle === "新增项目") {
            addProject(this_.projcetForm)
              .then((response) => {
                this_.$message({
                  message: "新增项目成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          } else {
            modifyProject(this_.projcetForm)
              .then((response) => {
                this_.$message({
                  message: "项目修改成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-project-manage {
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  .operation-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .operation-table {
    height: calc(100vh - 210px);
  }
}
</style>
