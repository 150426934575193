<template>
  <div class="login">
    <div class="formarea">
      <el-form ref="loginForm" :model="form" label-width="90px" :rules="rules">
        <el-form-item label="用户名:" prop="name">
          <el-input v-model="form.name" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="pass">
          <el-input
            v-model="form.pass"
            type="password"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSubmit('loginForm')"
            >登录</el-button
          >
          <el-button type="warning" size="small" @click="Visitor"
            >游客访问</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="ICP">
      <a target="_blank" href="https://beian.miit.gov.cn/">黑ICP备2022003573号-1</a>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      @2022-2025   哈尔滨师范大学计算机科学与信息工程学院人工智能实验室 &nbsp;&nbsp;  All Rights Reserved
    </div>
  </div>
</template>

<script>
import { login, getUserInfo } from "../api/user";
import { checkLogin } from "../utils/public.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      form: {
        name: "",
        pass: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur"],
          },
        ],
        pass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            message: "请输入密码",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  mounted() {
    if (checkLogin()) {
      this.getUserInfo(this.$store.state.userInfo.userId);
    } else {
      this.$message("请输入用户名和密码登录系统!");
      this.$router.push("/");
    }
  },

  methods: {
    ...mapMutations(["SET_USERINFO"]),
    getUserInfo(userId) {
      let this_ = this;
      getUserInfo({
        userId
      })
        .then((response) => {
          this_.SET_USERINFO(response.data);
          this_.$router.push("/Layout/Index");
          console.log(response.data);
        })
        .catch((err) => {
          this_.$message({
            message: err,
            type: "error",
          });
          this_.$router.push("/");
        });
    },
    //
    onSubmit(formName) {
      let this_ = this;
      this_.$refs[formName].validate((valid) => {
        if (valid) {
          // 表单验证通过
          login(this_.form)
            .then(function (response) {
              console.log("登录接口返回信息", response);
              // 接口成功返回数据
              this_.$message({
                message: "登录成功！",
                type: "success",
              });
              this_.$cookies.set(
                "token",
                response.headers["authorization"],
                "1d"
              );
              this_.getUserInfo(response.data.userId);
            })
            .catch(function (error) {
              this_.$message({
                message: "登录出现了一些问题！",
                type: "error",
              });
            });
        } else {
          this_.$message({
            message: "你还没有填写用户名和密码哦！",
            type: "error",
          });
        }
      });
    },
    Visitor() {
      this.SET_USERINFO({ username: "游客身份" });
      this.$router.push("/Layout/Index");
    },
  },
};
</script>

<style lang="scss">
.login {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/login_bg.jpg");
  background-size: 100%;
  background-repeat: repeat-y;
  background-position: center;
  .formarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 600px;
    background-color: rgba($color: #fff, $alpha: 0.7);
    border-radius: 20px;
    .el-button {
      margin-right: 20px;
      margin-left: 20px;
      width: 80px;
      font-weight: 500;
    }
    .el-form-item__label {
      color: #000;
      font-weight: 500;
      font-size: 18px;
      font-family: "黑体";
    }
  }
  .ICP {
    /*margin-top: 20px;*/
    position: fixed;
    bottom: 30px;
    color: white;
    a {
      color: white;
      text-decoration: none;
    }
  }
}
</style>
