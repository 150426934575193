<template>
  <div class="register-manage">
    <div class="operationline">
      <el-button size="mini" @click="registButton()" type="primary"
        >注册新用户</el-button
      >
    </div>
    <div class="operation-table">
      <vxe-table
        border
        stripe
        round
        :row-config="{ isCurrent: true, isHover: true }"
        empty-text="没有更多数据了！"
        class="mytable-scrollbar"
        :column-config="{ resizable: true }"
        size="mini"
        align="center"
        ref="xTable"
        height="auto"
        :data="userList"
      >
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="userName" title="姓名"></vxe-column>
        <vxe-column field="userEmail" title="电子邮件"></vxe-column>
        <vxe-column field="roleId" title="系统角色">
          <template #default="{ row }">
            <el-tag size="mini" v-if="row.roleId == 1">教师</el-tag>
            <el-tag size="mini" v-else-if="row.roleId == 2">研究生</el-tag>
            <el-tag size="mini" v-else-if="row.roleId == 3">本科生</el-tag>
            <el-tab size="mini" v-else>用户角色异常</el-tab>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="100">
          <template #default="{ row }">
            <vxe-button
              type="text"
              icon="fa fa-edit"
              @click="editUserInfo(row)"
            ></vxe-button>
            <vxe-button
              type="text"
              icon="fa fa-trash-o"
              @click="delUserInfo(row)"
            ></vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!-- 注册用户的弹窗 -->
    <div class="registerUserForm">
      <vxe-modal
        :title="modalTitle"
        v-model="registerModal"
        width="600"
        height="auto"
      >
        <template>
          <vxe-form
            ref="registerForm"
            :data="registerUserInfo"
            size="mini"
            span="24"
            title-width="100"
            :rules="registerFormRule"
            @submit="submitRegisterForm"
          >
            <vxe-form-item
              title="电子邮件地址(用户登录):"
              field="userEmail"
              reset-value=""
              :item-render="{}"
            >
              <template v-slot="scope">
                <vxe-input
                  v-model="registerUserInfo.userEmail"
                  placeholder="请输入邮箱"
                  clearable
                ></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item
              title="真实姓名:"
              field="userName"
              reset-value=""
              :item-render="{ name: 'input' }"
            >
              <template v-slot="scope">
                <vxe-input
                  v-model="registerUserInfo.userName"
                  placeholder="请输入真实姓名"
                  clearable
                ></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item
              title="用户角色:"
              field="roleId"
              reset-value=""
              :item-render="{}"
            >
              <template v-slot="scope">
                <vxe-select
                  v-model="registerUserInfo.roleId"
                  placeholder="请选择用户角色"
                  clearable
                >
                  <vxe-option
                    v-for="(item, index) in roleList"
                    :key="index"
                    :value="item.roleId"
                    :label="item.roleName"
                  ></vxe-option>
                </vxe-select>
              </template>
            </vxe-form-item>
            <vxe-form-item align="center" span="24" :item-render="{}">
              <template>
                <vxe-button type="submit" status="primary">提交</vxe-button>
                <vxe-button type="reset">重置</vxe-button>
              </template>
            </vxe-form-item>
          </vxe-form>
        </template>
      </vxe-modal>
    </div>
  </div>
</template>

<script>
import {
  getUserList,
  registerUser,
  modifyUserInfo,
  delUserInfo,
} from "../../api/user";
import { getRoleList } from "../../api/role";

export default {
  data() {
    return {
      //
      operatedUserId: "",
      // 所有用户的列表
      userList: [],
      // 注册用户的表单数据
      registerUserInfo: {
        userEmail: "",
        userName: "",
        roleId: "",
      },
      registerModal: false,
      modalTitle: "",
      registerFormRule: {
        userName: [
          { required: true, message: "请输入用户真实姓名" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符" },
        ],
        roleId: [{ required: true, message: "请选择用户的角色" }],
        userEmail: [{ required: true, message: "请填写用户的电子邮箱" }],
      },
      // 系统角色信息
      roleList: [],
    };
  },
  beforeMount() {
    this.getRoleList();
    this.getUserList();
  },
  methods: {
    // 提交注册表单数据
    submitRegisterForm(data, event) {
      let this_ = this;
      let params = data.data;
      if (
        params.userEmail == "" ||
        params.userName == "" ||
        params.roleId == ""
      ) {
        this_.$message({
          message: "请填写完整表单",
          type: "error",
        });
      } else {
        if (this_.modalTitle == "用户注册") {
          registerUser(data.data)
            .then((response) => {
              this_.$message({
                message: "注册成功",
                type: "success",
              });
            })
            .catch((error) => {
              this_.$message({
                message: error,
                type: "error",
              });
            });
        } else if (this_.modalTitle == "修改信息") {
          params = {
            userId: this_.operatedUserId,
            userName: data.data.userName,
            userEmail: data.data.userEmail,
            roleId: data.data.roleId,
          };
          console.log("params", params);
          modifyUserInfo(params)
            .then((response) => {
              this_.$message({
                message: "用户信息修改成功",
                type: "success",
              });
            })
            .catch((error) => {
              this_.$message({
                message: error,
                type: "error",
              });
            });
        }
      }
    },
    registButton() {
      console.log(this.$refs);
      this.registerUserInfo = {
        userEmail: "",
        userName: "",
        roleId: "",
      };
      this.modalTitle = "用户注册";
      this.registerModal = true;
    },
    // 获取所有的用户注册信息（不包括用户基础信息）
    getUserList() {
      let this_ = this;
      getUserList()
        .then((response) => {
          this_.userList = response.data;
        })
        .catch((err) => {
          this_.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取系统角色列表
    getRoleList() {
      let this_ = this;
      console.log("开始系统角色");
      getRoleList()
        .then((response) => {
          this_.roleList = response.data;
        })
        .catch((err) => {
          this_.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 点击编辑按钮触发
    editUserInfo(row) {
      console.log(row);
      this.registerUserInfo = {
        userEmail: row.userEmail,
        userName: row.userName,
        roleId: row.roleId,
      };
      this.modalTitle = "修改信息";
      this.operatedUserId = row.userId;
      this.registerModal = true;
    },
    // 删除用户信息
    delUserInfo(row) {
      console.log(row);
      let this_ = this;
      this.operatedUserId = row.userId;
      this.$XModal
        .confirm("您确定要删除吗？此操作会删除用户的所有信息！！！")
        .then((type) => {
          // 调用删除用户信息的接口
          if (type == "confirm")
            delUserInfo({ userId: row.userId })
              .then((response) => {
                this_.$message({
                  message: "用户信息已删除",
                  type: "success",
                });
                // TODO: 调用对应角色实体的详细信息表的删除接口（前端和后端谁来实现？）
                if (parseInt(row.roleId) == 1) {
                  // TODO: 调用教师表删除接口
                } else if (parseInt(row.roleId) == 2) {
                  // TODO: 调用研究生表删除接口
                } else if (parseInt(row.roleId) == 3) {
                  // TODO: 调用本科生表删除接口
                } else {
                  this_.$message({
                    message: "用户角色有误，无法删除相关信息。",
                    type: "error",
                  });
                }
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.register-manage {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .operationline,
  .operation-table {
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
  }
  .operationline {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .el-button {
      margin: 10px;
    }
  }
  .operation-table {
    height: calc(100vh - 200px);

    /*滚动条整体部分*/
    .mytable-scrollbar ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    /*滚动条的轨道*/
    .mytable-scrollbar ::-webkit-scrollbar-track {
      background-color: #ffffff;
    }
    /*滚动条里面的小方块，能向上向下移动*/
    .mytable-scrollbar ::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
      border-radius: 5px;
      border: 1px solid #f1f1f1;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
      background-color: #a8a8a8;
    }
    .mytable-scrollbar ::-webkit-scrollbar-thumb:active {
      background-color: #787878;
    }
    /*边角，即两个滚动条的交汇处*/
    .mytable-scrollbar ::-webkit-scrollbar-corner {
      background-color: #ffffff;
    }
  }
}
</style>
