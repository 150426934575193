<template>
  <div class="management-layout">
    <el-container class="container-one">
      <el-header>
        <div class="title">
          哈尔滨师范大学计算机科学与信息工程学院人工智能实验室后台管理系统
        </div>
        <div class="button-group">
          <el-button size="small" class="exit-button" @click="exitManageSys()"
            >退出管理系统</el-button
          >
        </div>
      </el-header>
      <el-container class="container-two">
        <el-aside width="200px">
          <management-aside></management-aside>
        </el-aside>
        <el-container>
          <el-main>
            <router-view></router-view>
          </el-main>
          <el-footer>
            <div class="ICP">
              <a target="_blank" href="https://beian.miit.gov.cn/">黑ICP备2022003573号-1</a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              @2022-2025 哈尔滨师范大学计算机科学与信息工程学院人工智能实验室  &nbsp;&nbsp;  All Rights Reserved
            </div>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import ManagementAside from "./ManagementAside.vue";

export default {
  components: { ManagementAside },
  data() {
    return {};
  },
  methods: {
    exitManageSys() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.management-layout {
  width: 100%;
  height: 100%;
  .container-one {
    height: 100%;
    .el-header,
    .el-footer {
      background-color: #790000;
    }
    .el-aside {
      background-color: #790000;
    }
    .el-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .title {
        padding-left: 10px imo !important;
        font-size: 24px;
        font-family: "黑体";
        font-weight: bold;
        color: #fff;
      }
      .exit-button {
        color: #790000;
        font-weight: bold;
      }
    }
  }
  .container-two {
    .el-main {
      padding: 0;
      background-color: #d8d8d8;
      overflow-y: scroll;
    }
    .el-footer {
      .ICP {
        margin-top: 20px;
        color: white;
        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
