import { render, staticRenderFns } from "./UserMatchManage.vue?vue&type=template&id=3ffe5cba&scoped=true"
import script from "./UserMatchManage.vue?vue&type=script&lang=js"
export * from "./UserMatchManage.vue?vue&type=script&lang=js"
import style0 from "./UserMatchManage.vue?vue&type=style&index=0&id=3ffe5cba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffe5cba",
  null
  
)

export default component.exports