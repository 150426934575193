<template>
  <div class="thesis">
    <el-card
      shadow="hover"
      class="thesis-card"
      v-for="(item, index) in thesislist"
      :key="index"
    >
      <el-descriptions
        :title="item.thesisTitle"
        border
        :column="3"
        size="mini"
        colon
      >
        <el-descriptions-item
          label="第一作者"
          :contentStyle="{ width: '150px' }"
          :labelStyle="{ 'font-weight': 'bold', width: '60px' }"
        >
          <el-tag size="small">{{ item.firstAuthor }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item
          label="通信作者"
          :contentStyle="{ width: '150px' }"
          :labelStyle="{ 'font-weight': 'bold', width: '60px' }"
        >
          <el-tag size="small">{{ item.corresAuthor }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item
          label="其他作者"
          :contentStyle="{ width: '200px' }"
          :labelStyle="{ 'font-weight': 'bold', width: '60px' }"
        >
          {{ item.otherAuthor }}
        </el-descriptions-item>
        <el-descriptions-item
          label="期刊"
          label-class-name="my-label"
          :contentStyle="{ 'text-align': 'left', width: '300px' }"
          :labelStyle="{ 'font-weight': 'bold', width: '40px' }"
          >{{ item.journal }}</el-descriptions-item
        >
        <el-descriptions-item
          label="发表日期"
          :labelStyle="{ 'font-weight': 'bold', width: '60px' }"
          :contentStyle="{ 'text-align': 'left', width: '100px' }"
        >
          <el-tag size="small">{{ item.publishDate }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["thesislist"],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.thesis-card {
  margin-top: 10px;
  border-radius: 12px !important;
  .my-label {
    width: 8em;
    padding: 0;
  }
  .author-tag {
    margin-left: 5px;
  }
}
</style>
