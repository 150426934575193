<template>
  <div class="individual-manage">
    <div class="personInfo">
      <el-descriptions
        class="margin-top"
        title="个人信息"
        :column="5"
        size="mini"
        border
        :labelStyle="{ width: '100px' }"
      >
        <template slot="extra">
          <el-button type="primary" size="mini" @click="openForm"
            >修改</el-button
          >
        </template>
        <!--  -->
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            姓名
          </template>
          {{ individualInfo.teacherName }}
          {{ individualInfo.name }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号
          </template>
          {{ individualInfo.phoneNumber }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-custom"></i>
            性别
          </template>
          {{ individualInfo.gender }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            工作或学习单位
          </template>
          {{ individualInfo.unit }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user-solid"></i>
            年龄
          </template>
          {{ individualInfo.age }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-picture"></i>
            照片
          </template>
          <img
            :src="individualInfo.photo"
            alt=""
            style="width: 100px; height: 140px"
          />
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item :span="5">
          <template slot="label">
            <i class="el-icon-s-order"></i>
            论文情况
          </template>
          {{ individualInfo.thesis }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item
          :span="5"
          v-if="individualInfo.majorCourse != undefined"
        >
          <template slot="label">
            <i class="el-icon-s-order"></i>
            主修课程
          </template>
          {{ individualInfo.majorCourse }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item
          :span="5"
          v-if="individualInfo.teaching != undefined"
        >
          <template slot="label">
            <i class="el-icon-s-order"></i>
            授课情况
          </template>
          {{ individualInfo.teaching }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item
          :span="5"
          v-if="individualInfo.monograph != undefined"
        >
          <template slot="label">
            <i class="el-icon-s-order"></i>
            专著与教材情况
          </template>
          {{ individualInfo.monograph }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item
          :span="5"
          v-if="individualInfo.experiment != undefined"
        >
          <template slot="label">
            <i class="el-icon-school"></i>
            工作与实习情况
          </template>
          {{ individualInfo.experiment }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item :span="5">
          <template slot="label">
            <i class="el-icon-office-building"></i>
            项目情况
          </template>
          {{ individualInfo.project }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item :span="5">
          <template slot="label">
            <i class="el-icon-cpu"></i>
            专利情况
          </template>
          {{ individualInfo.patent }}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item :span="5">
          <template slot="label">
            <i class="el-icon-medal-1"></i>
            获奖情况
          </template>
          {{ individualInfo.prize }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="formContainer">
      <vxe-modal
        v-model="formVisible"
        width="600"
        height="600"
        title="个人信息修改"
      >
        <template #default>
          <vxe-form
            ref="Xform"
            :data="individualInfo"
            @submit="submitModifiedInfo"
            title-width="100"
            title-align="right"
          >
            <vxe-form-item
              title="姓名"
              field="Name"
              :item-render="{}"
              :span="12"
            >
              <template #default="{ data }">
                <vxe-input
                  v-if="data.teacherName != undefined"
                  v-model="data.teacherName"
                  placeholder="请输入名称"
                  clearable
                  disabled
                ></vxe-input>
                <vxe-input
                  v-if="data.name != undefined"
                  v-model="data.name"
                  placeholder="请输入名称"
                  clearable
                  disabled
                ></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item
              title="联系电话"
              field="phoneNumber"
              :item-render="{}"
              :span="12"
            >
              <template #default="{ data }">
                <vxe-input
                  v-model="data.phoneNumber"
                  placeholder="请输入手机号"
                  clearable
                ></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item
              title="性别"
              field="gender"
              :item-render="{}"
              :span="12"
            >
              <template #default="{ data }">
                <vxe-select v-model="data.gender" placeholder="默认尺寸">
                  <vxe-option
                    v-for="item in ['男', '女']"
                    :key="item"
                    :value="item"
                    :label="`${item}`"
                  ></vxe-option>
                </vxe-select>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              title="年龄"
              field="age"
              :item-render="{}"
              :span="12"
            >
              <template #default="{ data }">
                <vxe-select v-model="data.age" placeholder="默认尺寸">
                  <vxe-option
                    v-for="item in 80"
                    :key="item"
                    :value="item.toString()"
                    :label="`${item.toString()}`"
                  ></vxe-option>
                </vxe-select>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              title="工作或学习单位"
              field="unit"
              :item-render="{}"
              :span="24"
            >
              <template #default="{ data }">
                <vxe-input
                  v-model="data.unit"
                  placeholder="工作或学习单位"
                  clearable
                ></vxe-input>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              title="免冠照"
              field="photo"
              :item-render="{}"
              :span="24"
            >
              <template #default="{}">
                <input
                  type="file"
                  class="uploadphoto"
                  @change="uploadphoto($event)"
                  ref="inputer"
                  multiple
                  accept="image/png,image/jpeg,image/jpg"
                />
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              title="论文情况"
              field="thesis"
              :item-render="{}"
              :span="24"
            >
              <template #default="{ data }">
                <vxe-textarea
                  v-model="data.thesis"
                  placeholder="工作或学习单位"
                  resize="vertical"
                  rows="8"
                ></vxe-textarea>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              v-if="individualInfo.majorCourse != undefined"
              title="主修课程"
              field="majorCourse"
              :item-render="{}"
              :span="24"
            >
              <template #default="{ data }">
                <vxe-textarea
                  v-model="data.majorCourse"
                  placeholder="主修课程信息"
                  resize="vertical"
                  rows="3"
                ></vxe-textarea>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              v-if="individualInfo.teaching != undefined"
              title="授课情况"
              field="teaching"
              :item-render="{}"
              :span="24"
            >
              <template #default="{ data }">
                <vxe-textarea
                  v-model="data.teaching"
                  placeholder="授课情况"
                  resize="vertical"
                  rows="3"
                ></vxe-textarea>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              v-if="individualInfo.monograph != undefined"
              title="专著与教材情况"
              field="monograph"
              :item-render="{}"
              :span="24"
            >
              <template #default="{ data }">
                <vxe-textarea
                  v-model="data.monograph"
                  placeholder="专著与教材情况"
                  resize="vertical"
                  rows="3"
                ></vxe-textarea>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              v-if="individualInfo.experiment != undefined"
              title="工作或实习经历"
              field="experiment"
              :item-render="{}"
              :span="24"
            >
              <template #default="{ data }">
                <vxe-textarea
                  v-model="data.experiment"
                  placeholder="工作或实习经历"
                  resize="vertical"
                  rows="3"
                ></vxe-textarea>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              title="项目情况"
              field="project"
              :item-render="{}"
              :span="24"
            >
              <template #default="{ data }">
                <vxe-textarea
                  v-model="data.project"
                  placeholder="项目情况"
                  resize="vertical"
                  rows="3"
                ></vxe-textarea>
              </template> </vxe-form-item
            >、
            <!--  -->
            <vxe-form-item
              title="专利情况"
              field="patent"
              :item-render="{}"
              :span="24"
            >
              <template #default="{ data }">
                <vxe-textarea
                  v-model="data.patent"
                  placeholder="专利情况"
                  resize="vertical"
                  rows="3"
                ></vxe-textarea>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item
              title="获奖情况"
              field="prize"
              :item-render="{}"
              :span="24"
            >
              <template #default="{ data }">
                <vxe-textarea
                  v-model="data.prize"
                  placeholder="获奖情况"
                  resize="vertical"
                  rows="3"
                ></vxe-textarea>
              </template>
            </vxe-form-item>
            <!--  -->
            <vxe-form-item align="center" span="24" :item-render="{}">
              <template>
                <vxe-button type="submit" status="primary">提交</vxe-button>
              </template>
            </vxe-form-item>
          </vxe-form>
        </template>
      </vxe-modal>
    </div>
  </div>
</template>

<script>
import { getTeacherInfoById, modifyTeacherInfoById } from "../../api/teacher";
import { getMasterInfoById, modifyMasterInfoById } from "../../api/master";
import {
  getBachelorInfoById,
  modifyBachelorInfoById,
} from "../../api/bachelor";
export default {
  data() {
    return {
      loginedUserRoleId: this.$store.state.userInfo.roleId,
      individualInfo: {},
      formVisible: false,
      fileList: [],
    };
  },
  beforeMount() {
    if (parseInt(this.loginedUserRoleId) == 1) {
      // 调用教师表根据ID获取教师信息的接口
      let this_ = this;
      getTeacherInfoById(this.$store.state.userInfo.userId)
        .then((response) => {
          this_.individualInfo = response.data;
        })
        .catch((err) => {
          this_.$message({
            message: err,
            type: "error",
          });
        });
    } else if (parseInt(this.loginedUserRoleId) == 2) {
      //  调用研究生表根据ID获取研究生信息的接口
      let this_ = this;
      getMasterInfoById(this.$store.state.userInfo.userId)
        .then((response) => {
          this_.individualInfo = response.data;
        })
        .catch((error) => {
          this_.$message({
            message: err,
            type: "error",
          });
        });
    } else if (parseInt(this.loginedUserRoleId) == 3) {
      // 调用本科生表根据ID获取本科生信息的接口
      let this_ = this;
      getBachelorInfoById(this.$store.state.userInfo.userId)
        .then((response) => {
          this_.individualInfo = response.data;
        })
        .catch((error) => {
          this_.$message({
            message: err,
            type: "error",
          });
        });
    } else {
      this.$message({
        message: "页面加载出现了一些问题。",
        type: "error",
      });
    }
  },
  methods: {
    openForm() {
      this.formVisible = true;
    },
    //
    confirmForm() {
      console.log(this.individualInfo);
    },

    uploadphoto(e) {
      let this_ = this;
      var file = e.target.files[0];
      var filesize = file.size;
      var filename = file.name;
      // 2,621,440   2M
      if (filesize > 2101440) {
        // 图片大于2MB
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
        var imgcode = e.target.result;
        this_.individualInfo.photo = imgcode;
      };
    },

    submitModifiedInfo() {
      let this_ = this;
      // 判断是哪一类用户 需要调用相应的接口
      if (parseInt(this.loginedUserRoleId) == 1) {
        this.individualInfo.teacherId = this.$store.state.userInfo.userId;
        // 调用教师表的修改接口
        modifyTeacherInfoById(this.individualInfo)
          .then((response) => {
            this_.$message({
              message: "个人信息更改成功。",
              type: "success",
            });
          })
          .catch((error) =>
            this_.$message({
              message: error,
              type: "eror",
            })
          );
      } else if (parseInt(this.loginedUserRoleId) == 2) {
        this.individualInfo.masterId = this.$store.state.userInfo.userId;
        // 调用研究生表的修改接口
        modifyMasterInfoById(this.individualInfo)
          .then((response) => {
            this_.$message({
              message: "个人信息更改成功。",
              type: "success",
            });
          })
          .catch((error) =>
            this_.$message({
              message: error,
              type: "eror",
            })
          );
      } else if (parseInt(this.loginedUserRoleId) == 3) {
        this.individualInfo.bachelorId = this.$store.state.userInfo.userId;
        // 调用本科生表的修改接口
        modifyBachelorInfoById(this.individualInfo)
          .then((response) => {
            this_.$message({
              message: "个人信息更改成功。",
              type: "success",
            });
          })
          .catch((error) =>
            this_.$message({
              message: error,
              type: "eror",
            })
          );
      } else {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.individual-manage {
  height: 100%;
  width: 100%;
  padding: 10px;
}
.individual-manage {
  .personInfo {
    height: calc(100vh - 140px);
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    .userName,
    .userPhoto {
      width: 45%;
      text-align: left;
    }
  }
}
</style>
