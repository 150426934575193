import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Layout from "../components/Loyout.vue";
import Index from "../components/HomePage/Index.vue";
import Intro from "../components/HomePage/Intro.vue";
import Teacher from "../components/HomePage/Teacher.vue";
import Student from "../components/HomePage/Student.vue";
import NotFound from "../components/NotFound.vue";
import ManagementLayout from "../components/management/management-layout.vue";
import RegisterManage from "../components/management/RegisterManage.vue";
import IndividualManage from "../components/management/IndividualManage.vue";
import ArticleManage from "../components/management/ArticleManage.vue";
import ProjectManage from "../components/management/ProjectManage.vue";
import MatchManage from "../components/management/MatchManage.vue";
import PatentManage from "../components/management/PatentManage.vue";
import ActiveManage from "../components/management/ActiveManage.vue";
import MetadataManage from "../components/management/MetadataManage.vue";
import BackupManage from "../components/management/BackupManage.vue";
import ManageWelcome from "../components/management/ManageWelcome.vue";

Vue.use(VueRouter);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/Layout",
    name: "Layout",
    redirect: "/Layout/Index",
    component: Layout,
    children: [
      {
        path: "/Layout/Index",
        name: "Index",
        component: Index,
      },
      {
        path: "/Layout/Intro",
        name: "Intro",
        component: Intro,
      },
      {
        path: "/Layout/Teacher",
        name: "Teacher",
        component: Teacher,
      },
      {
        path: "/Layout/Student",
        name: "Student",
        component: Student,
      },
    ],
  },
  {
    path: "/admin/",
    name: "ManagementLayout",
    component: ManagementLayout,
    children: [
      {
        path: "/admin/manage-welcome/:id",
        name: "manageWelcome",
        component: ManageWelcome,
      },
      {
        path: "/admin/register-manage/:id",
        name: "registerManage",
        component: RegisterManage,
      },
      {
        path: "/admin/individual-manage/:id",
        name: "individualManage",
        component: IndividualManage,
      },
      {
        path: "/admin/article-manage/:id",
        name: "articleManage",
        component: ArticleManage,
      },
      {
        path: "/admin/project-manage/:id",
        name: "projectManage",
        component: ProjectManage,
      },
      {
        path: "/admin/match-manage/:id",
        name: "matchManage",
        component: MatchManage,
      },
      {
        path: "/admin/patent-manage/:id",
        name: "patentManage",
        component: PatentManage,
      },
      {
        path: "/admin/active-manage/:id",
        name: "activeManage",
        component: ActiveManage,
      },
      {
        path: "/admin/metadata-manage/:id",
        name: "metadataManage",
        component: MetadataManage,
      },
      {
        path: "/admin/backup-manage/:id",
        name: "backupManage",
        component: BackupManage,
      },
    ],
  },
  {
    path: "/*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
