<template>
  <div class="user-thesis-manamge">
    <el-row class="operation-row">
      <el-button type="primary" size="mini" @click="addThesisEvent"
        >新增论文</el-button
      >
    </el-row>
    <el-divider></el-divider>
    <el-row class="operation-table">
      <vxe-table
        border="full"
        stripe
        align="center"
        :row-config="{ isCurrent: true, isHover: true }"
        :data="thesisData"
        max-height="100%"
      >
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="thesisTitle" title="论文题目"></vxe-column>
        <vxe-column
          field="firstAuthor"
          title="第一作者"
          width="100"
        ></vxe-column>
        <vxe-column
                field="corresAuthor"
                title="通信作者"
                width="100"
        ></vxe-column>
        <vxe-column
          field="otherAuthor"
          title="其他作者"
          width="250"
        ></vxe-column>
        <vxe-column field="journal" title="期刊"></vxe-column>
        <vxe-column
          field="publishDate"
          title="录用日期"
          width="100px"
        ></vxe-column>
        <vxe-column title="操作" width="100">
          <template #default="{ row }">
            <vxe-button
              type="text"
              icon="fa fa-edit"
              @click="editEvent(row)"
            ></vxe-button>
            <vxe-button
              type="text"
              icon="fa fa-trash-o"
              @click="removeEvent(row)"
            ></vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </el-row>
    <vxe-modal
      v-model="thesisModalVisible"
      width="600"
      height="400"
      :title="modalTitle"
    >
      <template #default>
        <vxe-form
          :title-width="100"
          title-align="right"
          :data="thesisForm"
          :rules="thesisFormRules"
          @submit="searchEvent"
          @reset="resetEvent"
          ref="thesisForm"
        >
          <vxe-form-item
                  title="论文编号"
                  field="thesisId"
                  :item-render="{}"
                  :span="24"
          >
            <template #default="{ data }">
              <vxe-input
                    v-model="data.thesisId"
                    placeholder="请输入论文编号"
                    clearable
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item
            title="论文题目"
            field="thesisTitle"
            :item-render="{}"
            :span="24"
          >
            <template #default="{ data }">
              <vxe-input
                v-model="data.thesisTitle"
                placeholder="请输入论文题目"
                clearable
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item title="第一作者" field="firstAuthor" :span="24">
            <template #default="{ data }">
              <vxe-input v-model="data.firstAuthor" disabled></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item
                  title="通信作者"
                  field="corresAuthor"
                  :item-render="{}"
                  :span="24"
          >
            <template #default="{ data }">
              <vxe-input
                      v-model="data.corresAuthor"
                      placeholder="请输入通信作者"
                      clearable
              ></vxe-input>
            </template>
          </vxe-form-item>

          <vxe-form-item
            title="其他作者"
            field="otherAuthor"
            :item-render="{}"
            :span="24"
          >
            <template #default="{ data }">
              <vxe-input v-model="data.otherAuthor"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item
            title="期刊"
            field="journal"
            :item-render="{}"
            :span="24"
          >
            <template #default="{ data }">
              <vxe-input v-model="data.journal" resize="none"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item
            title="录用日期"
            field="publishDate"
            :item-render="{}"
            :span="24"
          >
            <template #default="{ data }">
              <vxe-input v-model="data.publishDate" type="date"></vxe-input>
            </template>
          </vxe-form-item>
          <vxe-form-item align="center" :span="24">
            <template #default>
              <vxe-button
                status="primary"
                content="手动提交方式"
                type="submit"
              ></vxe-button>
              <vxe-button content="重置" type="reset"></vxe-button>
            </template>
          </vxe-form-item>
        </vxe-form>
        <span style="color: red; font-weight: bold"
          >注:其他作者在填写的时候使用“,”隔开</span
        >
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import {
  getThesisListByUserId,
  modifyThesisById,
  addThesis,
  delThesis,
} from "../../../api/thesis";
import * as obj from "xe-utils";

export default {
  data() {
    return {
      isAddThesis: false,
      // 论文列表数据
      thesisData: [],
      // 论文新增和修改窗口
      thesisModalVisible: false,
      modalTitle: "新增论文",
      // 论文表单数据
      thesisForm: {
        thesisId: "",
        thesisTitle: "",
        authorId: this.$store.state.userInfo.userId,
        firstAuthor: this.$store.state.userInfo.userName,
        corresAuthor: "",
        otherAuthor: [],
        journal: "",
        publishDate: "",
      },
      thesisFormRules: {
        thesisId: [{ required: true, message: "请输入论文编号" }],
        thesisTitle: [{ required: true, message: "请输入论文题目" }],
        corresAuthor: [{ required: true, message: "请输入通信作者" }],
        journal: [{ required: true, message: "请输入期刊名称" }],
        publishDate: [{ required: true, message: "请选择录用时间" }],
      },
    };
  },

  beforeMount() {
    getThesisListByUserId(this.$store.state.userInfo.userId)
      .then((response) => {
        console.log(response.data);
        this.thesisData = response.data;
      })
      .catch((error) => {
        console.log(
          this.$message({
            message: error,
            type: "error",
          })
        );
      });
  },

  methods: {
    addThesisEvent() {
      this.modalTitle = "新增论文";
      this.isAddThesis = true;
      this.thesisModalVisible = true;
      this.thesisForm = {
          thesisId: "",
          thesisTitle: "",
          authorId: this.$store.state.userInfo.userId,
          firstAuthor: this.$store.state.userInfo.userName,
          corresAuthor: "",
          otherAuthor: [],
          journal: "",
          publishDate: "",
        };
    },

    editEvent(row) {
      this.modalTitle = "修改论文";
      this.isAddThesis = false;
      this.thesisForm = row;
      this.thesisModalVisible = true;
    },

    removeEvent(row) {
      let this_ = this;
      this.$XModal.confirm("您确定要删除论文吗？").then((type) => {
        console.log(type);
        if (type == "cancel") {
        } else {
          // 调用删除论文的接口
          delThesis({thesisId: row.thesisId})
            .then((response) => {
              this_.$message({
                message: "论文成功删除",
                type: "success",
              });
            })
            .catch((error) => {
              this_.$message({
                message: error,
                type: "error",
              });
            });
        }
      });
    },

    resetEvent() {},

    searchEvent() {
      let this_ = this;
      this.$refs.thesisForm.validate((result) => {
        if (result === undefined) {
          if (this_.isAddThesis) {
            // 调用新增论文的接口
            console.log("调用新增论文的接口");
            addThesis(this_.thesisForm)
              .then((response) => {
                this_.$message({
                  message: "论文提交成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          } else {
            // TODO:调用修改论文的接口
            modifyThesisById(this_.thesisForm)
              .then((response) => {
                this_.$message({
                  message: "论文内容修改成功",
                  type: "success",
                });
              })
              .catch((error) => {
                this_.$message({
                  message: error,
                  type: "error",
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-thesis-manamge {
  height: 100%;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  .operation-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .operation-table {
    height: calc(100vh - 210px);
  }
}
</style>
