import request from "../https/request.js";

export function getBachelorList() {
  return request({
    url: "bachelor",
    method: "get",
  });
}

//
export function getBachelorInfoById(id) {
  return request({
    url: "/bachelor/" + id,
    method: "get",
  });
}

export function modifyBachelorInfoById(data) {
  return request({
    url: "/bachelor/modify",
    method: "post",
    data,
  });
}
