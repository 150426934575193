<template>
  <div class="match-manage">
    <admin-match-manage
      v-if="$store.state.userInfo.roleId === 4"
    ></admin-match-manage>
    <user-match-manage v-else></user-match-manage>
  </div>
</template>

<script>
import UserMatchManage from "./ordinaryUser/UserMatchManage.vue";
import AdminMatchManage from "./systemAdmin/AdminMatchManage.vue";

export default {
  components: {
    "user-match-manage": UserMatchManage,
    "admin-match-manage": AdminMatchManage,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.match-manage {
  height: 100%;
  padding: 10px;
}
</style>
