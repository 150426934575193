import request from "../https/request";

export function getActivityList() {
  return request({
    url: "/active",
    method: "get",
  });
}

export function getActiveListByUserId(userId) {
  return request({
    url: "/active/" + userId,
    method: "get",
  });
}

export function delActive(data) {
  return request({
    url: "/active",
    method: "delete",
    params: data
  });
}

export function addActive(data) {
  return request({
    url: "/active",
    method: "post",
    data,
  });
}

export function modifyActive(data) {
  return request({
    url: "/active/modify",
    method: "post",
    data,
  });
}
