<template>
  <div class="management-aside">
    <el-menu
      :default-active="activeIndex"
      :router="false"
      class="el-menu-demo"
      background-color="#790000"
      text-color="#fff"
      active-text-color="#ffd04b"
      @select="handleSelect"
    >
      <el-menu-item index="/admin/manage-welcome/"
        ><svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xitongguanli_yonghuguanli"></use></svg
        >首页
      </el-menu-item>
      <el-menu-item index="/admin/register-manage/"
        ><svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xitongguanli_yonghuguanli"></use></svg
        >用户管理
      </el-menu-item>
      <el-menu-item index="/admin/individual-manage/">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-kerenxinxiguanli"></use>
        </svg>
        个人信息管理
      </el-menu-item>
      <el-menu-item index="/admin/article-manage/">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-lunwenfabiaoshuliang"></use></svg
        >论文管理
      </el-menu-item>
      <el-menu-item index="/admin/project-manage/">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xiangmuguanli"></use></svg
        >项目管理
      </el-menu-item>
      <el-menu-item index="/admin/match-manage/">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-bisai"></use></svg
        >比赛管理
      </el-menu-item>
      <el-menu-item index="/admin/patent-manage/">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-zhuanliyuce"></use></svg
        >专利管理
      </el-menu-item>
      <el-menu-item index="/admin/active-manage/">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-huodongguanli"></use></svg
        >活动管理
      </el-menu-item>
      <el-menu-item index="/admin/metadata-manage/">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-ziliaoku"></use></svg
        >研究资料管理
      </el-menu-item>
      <el-menu-item index="/admin/backup-manage/">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-beifen"></use></svg
        >数据备份
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      activeIndex: this.$store.state.adminMenu,
    };
  },
  beforeUpdate() {},
  beforeMount() {
    let path = this.$route.fullPath.split("/");
    this.activeIndex = "/" + path[1] + "/" + path[2] + "/";
  },
  methods: {
    ...mapMutations(["SET_ADMINMENU"]),
    handleSelect(key) {
      this.SET_ADMINMENU(key);
      this.$router.replace(key + this.$store.state.userInfo.userId);
    },
  },
};
</script>

<style lang="scss" scoped>
.management-aside {
  width: 100%;
  height: auto;
  .el-menu {
    .el-menu-item {
      vertical-align: text-bottom;
      font-size: 16px;
      svg {
        margin-right: 3px;
      }
    }
  }
}
</style>
