import request from "../https/request";

export function getMetadataList() {
    return request({
        url: "/metadata",
        method: "get",
    });
}

export function getMetadataListByUserId(userId) {
    return request({
        url: "/metadata/" + userId,
        method: "get",
    });
}

export function delMetadata(data) {
    return request({
        url: "/metadata",
        method: "delete",
        params: data
    });
}

export function addMetadata(data) {
    return request({
        url: "/metadata",
        method: "post",
        data,
    });
}

export function modifyMetadata(data) {
    return request({
        url: "/metadata/modify",
        method: "post",
        data
    });
}
